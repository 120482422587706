import React, { useRef, useCallback } from 'react'
import { Polygon } from "@react-google-maps/api";

const PolygonComponent = (props) => {
    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            props.getMapDetails(props.index, nextPath)
        }
    }, [props]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    // console.log("The path state is", path);
    // console.log(props)
    return (
        <>
            <Polygon
                editable={props.editable}
                draggable={props.draggable}
                paths={props.points}
                onMouseUp={onEdit}
                onDragEnd={onEdit}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{ strokeColor: props.hightlightZoneId ? "black" : props.color_code, fillColor: props.color_code }}
                visible={props.visible}
            />
        </>
    )
}

export default PolygonComponent