import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./redux/actions/TokenAction";

import { Template } from "./components/Template";
import Login from "./Authentication/Login";
import Logout from "./Authentication/Logout";
import ForgotPassword from "./Authentication/ForgotPassword";
import ResetPassword from "./Authentication/ResetPassword";

import { Dashboard } from "./container/Dashboard";
import ViewPartners from "./container/PartnersManagement/Partners/ViewPartners";
import AddPartners from "./container/PartnersManagement/Partners/AddPartners";
import ViewSites from "./container/PartnersManagement/SiteManagement/ViewSites";
import AddSites from "./container/PartnersManagement/SiteManagement/AddSites";
import PartnerDetails from "./container/PartnersManagement/Partners/PartnerDetails";
import ViewProduct from "./container/PartnersManagement/ProductManagement/ViewProduct";
import ViewVehicles from "./container/PartnersManagement/VehicleManagement/ViewVehicles";
import AddProduct from "./container/PartnersManagement/ProductManagement/AddProduct";
import AddVehicle from "./container/PartnersManagement/VehicleManagement/AddVehicle";
import SiteDetails from "./container/PartnersManagement/SiteManagement/SiteDetails";
import ViewWifi from "./container/PartnersManagement/SiteManagement/WifiManagement/ViewWifi";
import ViewZone from "./container/PartnersManagement/SiteManagement/ZoneManagement/ViewZone";
import ViewMap from "./container/PartnersManagement/SiteManagement/MapManagement/ViewMap";
import AddWifi from "./container/PartnersManagement/SiteManagement/WifiManagement/AddWifi";
import AddZone from "./container/PartnersManagement/SiteManagement/ZoneManagement/AddZone";
import ProductDetails from "./container/PartnersManagement/ProductManagement/ProductDetails";
import VehicleDetails from "./container/PartnersManagement/VehicleManagement/VehicleDetails";
import ViewQRCode from "./container/PartnersManagement/VehicleManagement/QRCodeManagement/ViewQRCode";
import ViewVehicleMap from "./container/PartnersManagement/VehicleManagement/VehicleMapManagement/ViewVehicleMap";
import ViewAllVehicles from "./container/VehiclesManagement/ViewAllVehicles";
import ViewRiders from "./container/UserManagement/ViewRiders";
import AddUsers from "./container/UserManagement/AddUsers";
import AddAdminUsers from "./container/UserManagement/Admin/AddAdminUsers";
import ViewGraphs from "./container/ReportsManagement/ViewGraphs";
import AddCsvDownload from "./container/ReportsManagement/AddCsvDownload";
import ViewAllTransactions from "./container/TransactionsManagement/ViewAllTransactions";
// import Test from "./container/Test";
import axios from "./axios";
import { useNavigate } from "react-router";
import { setUserDetails } from "./redux/actions/UserDetailsAction";
import ViewAdminUsers from "./container/UserManagement/Admin/ViewAdminUsers";
import AdminDetails from "./container/UserManagement/Admin/AdminDetails";
import ViewCashPayment from "./container/CashPayments/ViewCashPayment";
import Unauthorized from "./components/Unauthorized";
import ChangePassword from "./Authentication/ChangePassword";
// import ViewVehicleFromVehicle from "./container/VehiclesManagement/ViewVehicleFromVehicle";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (localStorage.getItem("tokenAdminEmoby")) {
    dispatch(setToken(localStorage.getItem("tokenAdminEmoby")));
  }

  const token = useSelector((state) => state.getToken.token);

  axios.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.status === 401) {
      if (error.response.config.url !== "login") {
        navigate('/logout')
      }
    } else if (error.response.status === 402) {
      navigate('/unauthorized')
    }
    return Promise.reject(error)
  })


  useEffect(() => {
    if (localStorage.getItem("tokenAdminEmoby")) {
      axios.get('auth_user')
        .then((response) => {
          dispatch(setUserDetails(response.data.data))
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              // navigate('/logout')
            } else if (error.response || error.response.status !== 200) {
              // setError(error.response.data.status.message);
            }
          }
        })
    }
  }, [token, dispatch])

  var routes = null
  if (token) {
    routes = (
      <Routes>
        <Route exact path="/" element={<Template><Dashboard /></Template>} />
        <Route exact path="/home" element={<Template><Dashboard /></Template>} />

        {/* First Header */}
        <Route exact path="/partners" element={<Template><ViewPartners /></Template>} />
        <Route exact path="/add-partners" element={<Template><AddPartners /></Template>} />
        <Route exact path="/add-partners/:partnerId" element={<Template><AddPartners /></Template>} />
        <Route exact path="/partners-details/:partnerId" element={<Template><PartnerDetails /></Template>} />

        <Route exact path="/partners-site/:partnerId" element={<Template><ViewSites /></Template>} />
        <Route exact path="/partners-add-sites/:partnerId" element={<Template><AddSites /></Template>} />
        <Route exact path="/partners-add-sites/:partnerId/:siteId" element={<Template><AddSites /></Template>} />
        <Route exact path="/partners-sites-details/:partnerId/:siteId" element={<Template><SiteDetails /></Template>} />

        <Route exact path="/partners-sites-wifi/:partnerId/:siteId" element={<Template><ViewWifi /></Template>} />
        <Route exact path="/partners-add-wifi/:partnerId/:siteId" element={<Template><AddWifi /></Template>} />
        <Route exact path="/partners-add-wifi/:partnerId/:siteId/:wifiId" element={<Template><AddWifi /></Template>} />

        <Route exact path="/partners-sites-zone/:partnerId/:siteId" element={<Template><ViewZone /></Template>} />
        <Route exact path="/partners-add-zone/:partnerId/:siteId" element={<Template><AddZone /></Template>} />

        <Route exact path="/partners-sites-map/:partnerId/:siteId" element={<Template><ViewMap /></Template>} />

        <Route exact path="/partners-product/:partnerId" element={<Template><ViewProduct /></Template>} />
        <Route exact path="/partners-add-product/:partnerId" element={<Template><AddProduct /></Template>} />
        <Route exact path="/partners-add-product/:partnerId/:productId" element={<Template><AddProduct /></Template>} />
        <Route exact path="/partners-product-details/:partnerId/:productId" element={<Template><ProductDetails /></Template>} />

        <Route exact path="/partners-vehicle/:partnerId" element={<Template><ViewVehicles /></Template>} />
        <Route exact path="/partners-add-vehicle/:partnerId" element={<Template><AddVehicle /></Template>} />
        <Route exact path="/partners-add-vehicle/:partnerId/:vehicleId" element={<Template><AddVehicle /></Template>} />
        <Route exact path="/partners-vehicle-details/:partnerId/:vehicleId" element={<Template><VehicleDetails /></Template>} />

        <Route exact path="/partners-vehicle-qrcode/:partnerId/:vehicleId" element={<Template><ViewQRCode /></Template>} />
        {/* <Route exact path="/partners-vehicle-map/:partnerId/:vehicleId" element={<Template><ViewVehicleFromVehicle /></Template>} /> */}
        <Route exact path="/partners-vehicle-map/:partnerId/:vehicleId" element={<Template><ViewVehicleMap /></Template>} />

        {/* second header */}
        <Route exact path="/vehicles-all/" element={<Template><ViewAllVehicles /></Template>} />
        <Route exact path="/vehicles-all/:param" element={<Template><ViewAllVehicles /></Template>} />

        {/* third header */}
        <Route exact path="/users-all" element={<Template><ViewRiders /></Template>} />
        <Route exact path="/add-users" element={<Template><AddUsers /></Template>} />

        <Route exact path="/admin-users" element={<Template><ViewAdminUsers /></Template>} />
        <Route exact path="/add-admin-users" element={<Template><AddAdminUsers /></Template>} />
        <Route exact path="/add-admin-users/:adminId" element={<Template><AddAdminUsers /></Template>} />
        <Route exact path="/admin-details/:adminId" element={<Template><AdminDetails /></Template>} />

        {/* fourth header */}
        <Route exact path="/graphs" element={<Template><ViewGraphs /></Template>} />
        <Route exact path="/csv-download" element={<Template><AddCsvDownload /></Template>} />

        {/* fifth header */}
        <Route exact path="/transactions-all" element={<Template><ViewAllTransactions /></Template>} />

        {/* sixth header */}
        <Route exact path="/cash-payments" element={<Template><ViewCashPayment /></Template>} />


        <Route exact path="/unauthorized" element={<Template><Unauthorized /></Template>} />

        <Route exact path="/change-password" element={<Template><ChangePassword /></Template>} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/logout/:logoutFlag" element={<Logout />} />
        <Route path="*" element={<Template><Dashboard /></Template>} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<Login />} />
      </Routes>
    );
  }

  return (
    <div>
      {routes}
    </div>
  );
}

export default App;
