import React, { useState, useEffect } from 'react'
import PartnerTabsHeader from '../PartnerTabsHeader';
import { useParams, useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import axios from "../../../axios"
import { ToastContainer, toast } from 'react-toastify';
import Alertbox from '../../../components/Alertbox';
import Loader from '../../../components/Loader';

const ViewProduct = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { partnerId } = useParams();

    const [productList, setProductList] = useState([])
    const [productListOriginal, setProductListOriginal] = useState([])
    const [serviceCall, setServiceCall] = useState(true)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("")
    const [alertDelete, setAlertDelete] = useState("")
    const [alertIdDelete, setAlertIdDelete] = useState("")
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (serviceCall) {
            setLoader(true)
            axios.get('partner_products?partner_id=' + partnerId)
                .then((response) => {
                    setProductList(response.data.data)
                    setProductListOriginal(response.data.data)
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [serviceCall, partnerId, location, navigate])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempProduct = [...productListOriginal].filter(item => (item.name.toLowerCase().includes(searchValue.toLowerCase())));
        setProductList(tempProduct)
    }

    const deleteHandler = (id) => {
        setAlertDelete("Are you sure you want to delete product?")
        setAlertIdDelete(id);
    }

    const alertResponseDelete = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .delete("partner_product/" + alertId)
                .then((response) => {
                    setSuccess(response.data.status.message);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlertDelete("")
    }

    return (
        <div className="card">
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="card-body custom-tab">
                <PartnerTabsHeader active="partners-product" parameters={"/" + partnerId} />
                <div>
                    <div>

                        {alertDelete ?
                            <Alertbox
                                message={alertDelete}
                                alertId={alertIdDelete}
                                alertResponse={alertResponseDelete} />
                            : null}
                        <div className="card">
                            <div className="card-header p-3">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h5>
                                            <div className="mb-3 m-form__group">
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="fa fa-search"></i></span>
                                                    <input
                                                        className="form-control" type="text"
                                                        placeholder="Search"
                                                        onChange={(e) => searchHandler(e.target.value)}
                                                        value={search}
                                                    />
                                                </div>
                                            </div>
                                        </h5>
                                    </div>
                                    <div className="col-sm-8">
                                        <h5 className="add-partners-btn">
                                            <Link to={"/partners-add-product/" + partnerId}>
                                                <button className="btn btn-emoby float-right" data-bs-original-title="" title=""><i className="fa fa-plus"></i> ADD PRODUCT
                                                </button>
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive" id="customers">
                                <table className="table table-hover">
                                    <thead className="bg-dark">
                                        <tr>
                                            <th scope="col" className="text-light-custom">NAME</th>
                                            {/* <th scope="col" className="text-light-custom">PARTNER</th> */}
                                            <th scope="col" className="text-light-custom">SYSTEM COST</th>
                                            <th scope="col" className="text-light-custom">PARTNER COST</th>
                                            <th scope="col" className="text-light-custom"> COST</th>
                                            <th scope="col" className="text-light-custom">PROMOTIONAL</th>
                                            <th scope="col" className="text-light-custom">DURATION
                                            (In minutes)</th>
                                            <th scope="col" className="text-light-custom">ENABLED</th>
                                            <th scope="col" className="text-light-custom">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productList.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link
                                                        to={"/partners-product-details/" + partnerId + "/" + product.id} style={{ color: "black", textDecoration: "underline" }}>
                                                        {product.name}
                                                    </Link>
                                                </td>
                                                <td>{product.system_cost}</td>
                                                <td>{product.partner_cost}</td>
                                                <td>{product.cost}</td>
                                                <td>{(product.promotional === 1) ? "Yes" : "No"}</td>
                                                <td>{product.duration}</td>
                                                <td>{(product.enabled === 1) ? "Yes" : "No"}</td>
                                                <td>

                                                    <span
                                                        style={{ cursor: "pointer", fontSize: "18px" }}
                                                        onClick={() => deleteHandler(product.id)}>
                                                        <i className="fa fa-trash"></i>
                                                    </span> &nbsp;

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-sm-6 d-flex align-items-center p-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewProduct