import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import axios from '../../axios'
import Loader from '../../components/Loader'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const ViewGraphs = () => {
  const navigate = useNavigate();

  const [series, setSeries] = useState([])
  const [series2, setSeries2] = useState([])
  const [categories, setCategories] = useState([])
  const [loader, setLoader] = useState(false)
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setLoader(true)
    axios.get("partner_transactions_month_to_date")
      .then((response) => {
        setSeries(response.data.data.series)
        setLoader(false)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            navigate('/logout')
          } else if (error.response.status === 402) {
            navigate('/unauthorized')
          } else if (error.response || error.response.status !== 200) {
            setError(error.response.data.status.message);
            setLoader(false)
          }
        }
      })
  }, [navigate])

  useEffect(() => {
    setLoader(true)
    axios.get("partner_transactions_total")
      .then((response) => {
        setSeries2(response.data.data.series)
        setCategories(response.data.data.xAxis.categories)
        setLoader(false)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            navigate('/logout')
          } else if (error.response.status === 402) {
            navigate('/unauthorized')
          } else if (error.response || error.response.status !== 200) {
            setError(error.response.data.status.message);
            setLoader(false)
          }
        }
      })
  }, [navigate])

  const options = {
    chart: {
      type: 'column'
    },
    colors: ['rgb(250, 182, 0)', 'rgb(242, 142, 0)'],
    title: {
      text: 'Partner Transactions (Month to Date)'
    },
    xAxis: {
      categories: ['eMoby']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'value'
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'gray'
        }
      }
    },
    // legend: {
    //   align: 'right',
    //   x: -30,
    //   verticalAlign: 'top',
    //   y: 25,
    //   floating: true,
    //   backgroundColor:
    //     Highcharts.defaultOptions.legend.backgroundColor || 'white',
    //   borderColor: '#CCC',
    //   borderWidth: 1,
    //   shadow: false,
    // },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },
    series: series
    // series: [{
    //   name: 'Card',
    //   data: [400]
    // }, {
    //   name: 'Cash',
    //   data: [0]
    // }]
  }


  useEffect(() => {
    if (success) {
      toast.success(success, '', 3500);
      setSuccess('')
    }
  }, [success])

  useEffect(() => {
    if (error !== '') {
      toast.error(error, '', 5000);
      setError('')
    }
  }, [error])


  const optionsTwo = {
    chart: {
      type: 'column'
    },
    colors: ['rgb(250, 182, 0)', 'rgb(242, 142, 0)'],
    title: {
      text: 'Partner Transactions (Total)'
    },
    xAxis: {
      categories: categories
    },
    yAxis: {
      min: 0,
      title: {
        text: 'value'
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'gray'
        }
      }
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },
    series: series2
    // series: [{
    //   name: 'Card',
    //   data: [400,0,0,0,80]
    // }, {
    //   name: 'Cash',
    //   data: [200,89,90,0,90]
    // }]
  }


  return (
    <div>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <hr />

      <HighchartsReact
        highcharts={Highcharts}
        options={optionsTwo}
      />
    </div>
  )
}

export default ViewGraphs