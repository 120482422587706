import { combineReducers } from "redux";
import { tokenReducer } from "./TokenReducer";
import { userDetailsReducer } from "./UserDetailsReducer";
import { usertypeReducer } from "./UserTypeReducer";

const reducers = combineReducers({
    getToken: tokenReducer,
    getUserType: usertypeReducer,
    getUserDetails: userDetailsReducer
});

export default reducers;