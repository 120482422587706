import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/emoby-logo.png';
import { useSelector } from "react-redux";

export const Template = (props) => {
    const userDetails = useSelector((state) => state.getUserDetails.user_details);
    return (
        <>
            <header>
                <nav>
                    <h1 className="logo">
                        <Link to="/">
                            <img className="img-fluid for-light" src={Logo} alt="loginpage" />
                        </Link>
                    </h1>
                    <button onClick={() => window.menu()} className="menu_open"><i className="fa fa-bars"></i></button>
                    <div className="m_menu">
                        <button onClick={() => window.menu()} className="menu_close">&times;</button>
                        <ul className="normal">
                            <li className="menu_o">
                                <Link to="#" className="text-white">
                                    {(userDetails.user_type === 3) ? "SITES" : "PARTNERS"}
                                </Link>
                                {(userDetails.user_type === 3) ?
                                    <ul className="down-menu">
                                        <li><Link to={"/partners-sites-details/" + userDetails.partner_id + "/" + userDetails.site_id}>VIEW SITES</Link></li>
                                    </ul>
                                    :
                                    <ul className="down-menu">
                                        <li><Link to={(userDetails.user_type === 2) ? "/partners-details/" + userDetails.partner_id : "/partners"}>VIEW PARTNERS</Link></li>
                                    </ul>}
                            </li>
                            <li className="menu_o">
                                <Link to="#" className="text-white">VEHICLES</Link>
                                <ul className="down-menu">
                                    <li><Link to="/vehicles-all/all">ALL VEHICLES </Link></li>
                                    <li><Link to="/vehicles-all/in_use">VEHICLES IN USE </Link></li>
                                    <li><Link to="/vehicles-all/available">AVAILABLE VEHICLES </Link></li>
                                    <li><Link to="/vehicles-all/scanned">SCANNED VEHICLES </Link></li>
                                    <li><Link to="/vehicles-all/online">ONLINE VEHICLES </Link></li>
                                    <li><Link to="/vehicles-all/offline">OFFLINE VEHICLES</Link></li>
                                </ul>
                            </li>
                            {/* <li className="menu_o">
                                <Link to="/" className="text-white">SETTING</Link>
                            </li> */}
                            {(userDetails.user_type === 3) ? "" :
                                <li className="menu_o">
                                    <Link to="#" className="text-white">USERS</Link>
                                    <ul className="down-menu">
                                        <li><Link to="/users-all">RIDERS </Link></li>
                                        <li><Link to="/admin-users">ADMIN USERS </Link></li>
                                        <li><Link to="/add-admin-users">ADD ADMIN USER </Link></li>
                                    </ul>
                                </li>}

                            {(userDetails.user_type === 1) ?
                                <li className="menu_o">
                                    <Link to="#" className="text-white">REPORTS</Link>
                                    <ul className="down-menu">
                                        <li><Link to="/graphs">GRAPHS </Link></li>
                                        {/* <li><Link to="/csv-download">CSV DOWNLOAD </Link></li> */}
                                    </ul>
                                </li> : ""}

                            <li className="menu_o">
                                <Link to="#" className="text-white">TRANSACTIONS</Link>
                                <ul className="down-menu">
                                    <li><Link to="/transactions-all">ALL TRANSACTIONS</Link></li>
                                    {/* <li><Link to="/">TODAY'S TRANSACTIONS</Link></li>
                                    <li><Link to="/">YESTERDAY'S TRANSACTIONS</Link></li>
                                    <li><Link to="/">THIS MONTH'S TRANSACTIONS</Link></li>
                                    <li><Link to="/">LAST MONTH'S TRANSACTIONS</Link></li>
                                    <li><Link to="/">PAID TRANSACTIONS</Link></li> */}
                                </ul>
                            </li>
                            <li>
                                <Link className="button signin" to="/cash-payments">Cash Payments</Link>
                            </li>
                            <li className="menu_o">
                                <Link to="#" className="text-white">{userDetails.name}</Link>
                                <ul className="down-menu">
                                    {/* <li><Link to="/">PROFILE</Link></li> */}
                                    <li><Link to="/change-password">CHANGE PASSWORD</Link></li>
                                    <li><Link to="/logout/user">LOGOUT</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            {/* <div className="tap-top"><i data-feather="chevrons-up"></i></div> */}
            <div className="page-wrapper">
                <div className="container-fluid custom-container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
