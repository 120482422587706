import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import VehicleTabsHeader from '../VehicleTabsHeader';
import { LoadScript, GoogleMap} from "@react-google-maps/api";
import VehicleComponentMap from '../../SiteManagement/MapManagement/VehicleComponentMap';
import PolygonComponent from '../../SiteManagement/MapManagement/PolygonComponent';
import Loader from '../../../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import axios from "../../../../axios"
// import { OverlayView } from "@react-google-maps/api";
import { useSelector } from 'react-redux';


const ViewVehicleMap = (props) => {
    const navigate = useNavigate();
    const { partnerId } = useParams();
    const { vehicleId } = useParams();

    const userDetails = useSelector((state) => state.getUserDetails.user_details);

    const [mapDetails, setMapDetails] = useState([]);
    // const [imei, setimei] = useState('');
    // const [lat, setlat] = useState('');
    // const [lng, setlng] = useState('');
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState("")
    const [siteID, setSiteID] = useState("")
    const [partnerID, setPartnerID] = useState("")
    const [LatCenter, setLatCenter] = useState(-33.947594)
    const [LngCenter, setLngCenter] = useState(18.611929)
    const [imei, setImei] = useState("")

    useEffect(() => {
        if (siteID) {
            var partner_id = (parseInt(partnerId) > 0) ? partnerId : partnerID
            // var partner_id = partnerId ? partnerId : userDetails.partner_id
            if (partnerId !== undefined) {
                // var url = "all_zones"
                // if (!isNaN(parseInt(partnerId))) {
                //     url += "?partner_id=" + partner_id + "&site_id=" + siteID
                // }
                setLoader(true)
                axios.get("all_zones?partner_id=" + partner_id + "&site_id=" + siteID)
                    .then((response) => {
                        setMapDetails(response.data.data)
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            }
        }
    }, [navigate, partnerId, userDetails.partner_id, siteID, partnerID])

    useEffect(() => {
        if (vehicleId) {
            setLoader(true)
            axios.get("vehicle/" + vehicleId)
                .then((response) => {
                    setImei(response.data.data.imei)
                    setLatCenter(response.data.data.lat)
                    setLngCenter(response.data.data.lng)
                    setSiteID(response.data.data.site_id)
                    setPartnerID(response.data.data.partner_id)
                    // setimei(response.data.data.imei)
                    // setlat(response.data.data.lat)
                    // setlng(response.data.data.lng)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [vehicleId, navigate])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const getMapDetails = (index, points) => {
        var temp = JSON.parse(JSON.stringify(mapDetails));
        temp[index].points = points
        setMapDetails(temp)
    }

    // const getPoints = (e) => {
    //     console.log(e.latLng.lat())
    // }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="card">
                <div className="card-body custom-tab">
                    {(props.from === "dashboard") ? null :
                        <VehicleTabsHeader active="partners-vehicle-map" parameters={"/" + partnerId + "/" + vehicleId} />
                    }

                    <div className="row">
                        <div className="col-md-12" style={{ padding: "5px 0px" }}>
                            <LoadScript
                                id="script-loader"
                                googleMapsApiKey="AIzaSyAdyM6Ax5B6e6ZPzoWxOBBfY3JPIxeIyMI"
                                language="en"
                                region="us"
                            >
                                <GoogleMap
                                    mapContainerClassName="zone-map"
                                    center={{ lat: LatCenter, lng: LngCenter }}
                                    // center={center}
                                    zoom={12}
                                    version="weekly"
                                    on
                                >

                                    {/* {
                                        <VehicleComponentMap partnerID={partnerID} siteID={siteID} />
                                    } */}

                                    {(props.from === "dashboard") ?
                                        <VehicleComponentMap partnerId={partnerId} />
                                        :
                                        <VehicleComponentMap partnerId={partnerID} siteId={siteID} imei={imei}/>
                                    }

                                    {/* Hide zone in dashboard else shown in vehicle map page */}
                                    {
                                        (props.from === "dashboard") ? null :
                                            mapDetails.map((item, index) => {
                                                return (
                                                    <PolygonComponent
                                                        key={item.id}
                                                        index={index}
                                                        editable={false}
                                                        draggable={false}
                                                        points={item.points}
                                                        getMapDetails={getMapDetails}
                                                        // onClick={getPoints}
                                                        color_code={item.zone_type.color_code}
                                                    />
                                                )
                                            })
                                    }
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ViewVehicleMap