import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Loader";
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const ViewAllTransactions = () => {
    const navigate = useNavigate();

    const [transactionList, setTransactionList] = useState([])
    const [start_date, setStart_date] = useState(new Date());
    const [end_date, setEnd_date] = useState(new Date());
    const [status, setStatus] = useState("");
    const [payment_mode, setMode] = useState("");
    // const [search, setSearch] = useState("")
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [serviceCall, setServiceCall] = useState(true)


    useEffect(() => {
        const StartDate = start_date.toISOString().slice(0, 10);
        const EndDate = end_date.toISOString().slice(0, 10);
        if (serviceCall) {
            setLoader(true)
            axios.get("all_transactions?start_date=" + StartDate + "&end_date=" + EndDate + "& status=" + status + "&payment_mode=" + payment_mode)
                .then((response) => {
                    setTransactionList(response.data.data)
                    setLoader(false)
                    setServiceCall(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, end_date, start_date, status, payment_mode, serviceCall])

    const searchHandler = (e) => {
        e.preventDefault();
        setServiceCall(true)
    }

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    // const clearHandler = () => {
    //     setStart_date(new Date())
    //     setEnd_date(new Date())
    //     setStatus("")
    //     setMode("")
    //     setServiceCall(true)
    // }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="card">
                <div className="card-body custom-tab">
                    <div>
                        <div>
                            <div className="card">
                                <div className="card-header p-3">
                                    <form onSubmit={searchHandler}>
                                        <div className="row">
                                            <div className='col-sm-2'>
                                                <DatePicker
                                                    className='form-control'
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={start_date}
                                                    // minDate={start_date.setMonth(start_date.getMonth(), 1)}
                                                    onChange={(date: Date) => setStart_date(date)}
                                                />
                                            </div>
                                            <div className='col-sm-2'>
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    selected={end_date}
                                                    onChange={(date: Date) => setEnd_date(date)} />
                                            </div>
                                            <div className='col-sm-2'>
                                                <select
                                                    className="form-select digits"
                                                    id="status"
                                                    name='status'
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}>
                                                    <option value="">All Status</option>
                                                    <option value="1">Pending</option>
                                                    <option value="2">Approved</option>
                                                    <option value="3">Rejected</option>
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <select
                                                    className="form-select digits"
                                                    id="payment_mode"
                                                    name='payment_mode'
                                                    value={payment_mode}
                                                    onChange={(e) => setMode(e.target.value)}>
                                                    <option value="">All Payment Mode</option>
                                                    <option value="1">Cash</option>
                                                    <option value="2">Online</option>
                                                </select>
                                            </div>

                                            <div className='col-sm-2'>
                                                <button type="submit" className='btn btn-emoby'> Search</button>
                                            </div>

                                            {/* <div className='col-sm-2'>
                                                <button type="button" className='btn btn-emoby' onClick={clearHandler}> Clear</button>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>

                                <div className="table-responsive" id="customers">
                                    <table className="table table-hover">
                                        <thead className="bg-dark">
                                            <tr>
                                                <th scope="col" className="text-light-custom">OID</th>
                                                <th scope="col" className="text-light-custom">USER</th>
                                                <th scope="col" className="text-light-custom">PARTNER</th>
                                                <th scope="col" className="text-light-custom">VEHICLE</th>
                                                <th scope="col" className="text-light-custom">PRODUCT</th>
                                                <th scope="col" className="text-light-custom">SITE</th>
                                                <th scope="col" className="text-light-custom">STATUS</th>
                                                <th scope="col" className="text-light-custom">AMOUNT</th>
                                                <th scope="col" className="text-light-custom">DURATION</th>
                                                <th scope="col" className="text-light-custom">UPDATED</th>
                                                <th scope="col" className="text-light-custom">LICENSE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.order_id}</td>

                                                    <td>{item.rider !== null ? item.rider.name : ''}</td>

                                                    <td>{item.partner !== null ? item.partner.name : ''}</td>

                                                    <td>{item.vehicle !== null ? item.vehicle.imei : ''}</td>

                                                    <td>{item.product !== null ? item.product.name : ''}</td>

                                                    <td>{item.site !== null ? item.site.name : ''}</td>
                                                    <td>{(item.status === 1) ? "Pending" : (item.status === 2) ? "Approved" : "Rejected"}</td>
                                                    <td>{item.cost}</td>
                                                    <td>{item.duration}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        {(item.rider.driving_licence) ?
                                                            <a
                                                                href={item.rider.driving_licence}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="btn btn-emoby">
                                                                View
                                                            </a>
                                                            : null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="p-3" style={{ height:"191px" }}>
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center p-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ViewAllTransactions