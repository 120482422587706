import axios from "axios";

const instance = axios.create({
  // baseURL: "https://swtprime.com/emoby/api/admin/",
  // baseURL: "https://emoby-services.epixsa.com/api/admin/",
  baseURL: "https://services.emoby.co.za/api/admin/",
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    // 'Access-Control-Allow-Origin': '*',
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("tokenAdminEmoby")}`;
      },
    },
  },
});

// instance.interceptors.response.use((response) => {
//   return response
// }, (error) => {
//   return Promise.reject(error)
// })

export default instance;
