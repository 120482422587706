import React, { useState, useEffect } from 'react'
import SiteTabsHeader from '../SiteTabsHeader';
import { Link, useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import axios from "../../../../axios"
import Alertbox from '../../../../components/Alertbox';
import Loader from '../../../../components/Loader';

const ViewWifi = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { partnerId } = useParams();
    const { siteId } = useParams();

    const [wifiList, setWifiList] = useState([])
    const [wifiListOriginal, setWifiListOriginal] = useState([])
    const [serviceCall, setServiceCall] = useState(true)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("")
    const [alertDelete, setAlertDelete] = useState("")
    const [alertIdDelete, setAlertIdDelete] = useState("")
    const [loader, setLoader] = useState(false)


    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (serviceCall) {
            setLoader(true)
            axios.get('partner_site_wifis?partner_site_id=' + siteId)
                .then((response) => {
                    setWifiList(response.data.data)
                    setWifiListOriginal(response.data.data)
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [serviceCall, location, navigate, siteId])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempWifi = [...wifiListOriginal].filter(item => (item.ssid.toLowerCase().includes(searchValue.toLowerCase())));
        setWifiList(tempWifi)
    }

    const deleteHandler = (id) => {
        setAlertDelete("Are you sure you want to delete wifi?")
        setAlertIdDelete(id);
    }

    const alertResponseDelete = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .delete("partner_site_wifi/" + alertId)
                .then((response) => {
                    setSuccess(response.data.status.message);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlertDelete("")
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            {alertDelete ?
                <Alertbox
                    message={alertDelete}
                    alertId={alertIdDelete}
                    alertResponse={alertResponseDelete} />
                : null}
            <div className="card">
                <div className="card-body custom-tab">
                    <SiteTabsHeader active="partners-sites-wifi" parameters={"/" + partnerId + "/" + siteId} />
                    <div className="card">
                        <div className="card-header p-3">
                            <div className="row">
                                <div className="col-sm-4">
                                    <h5>
                                        <div className="mb-3 m-form__group">
                                            <div className="input-group">
                                                <span className="input-group-text"><i className="fa fa-search"></i></span>
                                                <input
                                                    className="form-control" type="text"
                                                    placeholder="Search"
                                                    onChange={(e) => searchHandler(e.target.value)}
                                                    value={search}
                                                />
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                                <div className="col-sm-8">
                                    <h5 className="add-partners-btn">
                                        <Link to={"/partners-add-wifi/" + partnerId + "/" + siteId}>
                                            <button className="btn btn-emoby float-right" data-bs-original-title="" title=""><i className="fa fa-plus"></i> ADD WIFI
                                            </button>
                                        </Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="customers">
                            <table className="table table-hover">
                                <thead className="bg-dark">
                                    <tr>
                                        <th scope="col" className="text-light-custom">NAME</th>
                                        <th scope="col" className="text-light-custom">PASSWORD</th>
                                        <th scope="col" className="text-light-custom">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {wifiList.map((wifi, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Link to={"/partners-add-wifi/" + partnerId + "/" + siteId + "/" + wifi.id} style={{ color: "black", textDecoration: "underline" }}>
                                                    {wifi.ssid}
                                                </Link>
                                            </td>
                                            {/* <td>{wifi.ssid}</td> */}
                                            <td>{wifi.password}</td>
                                            <td>
                                                <span
                                                    style={{ cursor: "pointer", fontSize: "18px" }}
                                                    onClick={() => deleteHandler(wifi.id)}>
                                                    <i className="fa fa-trash"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="p-3">
                                <div className="row">
                                    <div className="col-sm-6 d-flex align-items-center p-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewWifi