import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setToken } from "../redux/actions/TokenAction";
import Logo from '../images/large-logo.png'
import axios from "../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';
// import { setPartnerId, setSiteId, setUserType } from '../redux/actions/UserTypeAction';
import { setUserDetails } from '../redux/actions/UserDetailsAction';
import Loader from '../components/Loader';
// import { Link } from 'react-router-dom';

const Login = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();

    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        if (location.state !== null) {
            setError(location.state)
        }
    }, [location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])


    // const get_userDetails = () => {
    //     axios.get('auth_user')
    //         .then((response) => {
    //             console.log("auth",response.data.data)
    //             dispatch(setUserDetails(response.data.data))
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 if (error.response.status === 401) {
    //                     navigate('/logout')
    //                 } else if (error.response || error.response.status !== 200) {
    //                     setError(error.response.data.status.message);
    //                 }
    //             }
    //         })
    // }

    const submitForm = (e) => {
        e.preventDefault();
        setError('')

        if (email && password) {
            const loginData = { email: email, password: password };
            setLoader(true)
            axios
                .post("login", loginData)
                .then((response) => {
                    setLoader(false)
                    localStorage.setItem("tokenAdminEmoby", response.data.data.token);
                    dispatch(setToken(response.data.data.token));
                    // dispatch(setUserType(response.data.data.user_type))
                    // dispatch(setPartnerId(response.data.data.partner_id))
                    // dispatch(setSiteId(response.data.data.site_id))
                    dispatch(setUserDetails(response.data.data))


                    var partnerId = response.data.data.partner_id;
                    if (response.data.data.user_type === 1) {
                        navigate('/home');
                    } else if (response.data.data.user_type === 2) {
                        navigate('/partners-details/' + partnerId)
                    } else if (response.data.data.user_type === 3) {
                        navigate('/partners-sites-details/' + partnerId + '/' + response.data.data.site_id)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                            // navigate('/logout')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                });
        } else {
            setError('Please enter valid email & password')
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <nav>
                <div className="custom-nav">
                    <img className="img-fluid for-light" src={Logo} alt="looginpage" />
                </div>
            </nav>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="login-card">
                            <div>

                                <div className="login-main">
                                    <form className="theme-form" onSubmit={submitForm}>
                                        <h4>Sign in to account</h4>
                                        <p>Enter your email &amp; password to login</p>
                                        <div className="form-group">
                                            <label className="col-form-label">Email Address</label>
                                            <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="test@gmail.com" />
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Password</label>
                                            <div className="form-input position-relative">
                                                <input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="*********" />
                                                {/* <div className="show-hide"><span className="show"></span></div> */}
                                            </div>
                                        </div>

                                        <div className="form-group mb-0">
                                            <div className="checkbox p-0">
                                                <input id="checkbox1" type="checkbox" />
                                                {/* <Link to="/forgot-password"> */}
                                                    {/* Forgot password? */}
                                                {/* </Lin\k> */}
                                            </div>

                                            <div className="text-end mt-4">
                                                <button className="btn btn-primary btn-block w-100" type="submit">Sign in</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;