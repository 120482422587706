import React from 'react'
import VehicleComponent from '../PartnersManagement/VehicleManagement/VehicleComponent'
import { useParams } from 'react-router'

const ViewAllVehicles = () => {
  const { param } = useParams()
  return (
    <div>
      <VehicleComponent partnerId={param || ""} />
    </div>
  )
}

export default ViewAllVehicles