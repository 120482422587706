import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import VehicleTabsHeader from './VehicleTabsHeader';
import axios from '../../../axios'
import Loader from '../../../components/Loader';
import { useNavigate, useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Alertbox from '../../../components/Alertbox';
import VehicleTimer from '../../VehiclesManagement/VehicleTimer';

const VehicleDetails = () => {
    const navigate = useNavigate();
    // const { param } = useParams();
    const { partnerId } = useParams();
    const { vehicleId } = useParams();
    const location = useLocation();

    const [vehicleDetails, setVehicleDetails] = useState({
        id: 0,
        ts: "",
        imei: "",
        partner_id: 0,
        payment_status:"",
        site_id: 0,
        zone_id: 0,
        linked_rider: 0,
        lat: 0,
        lng: 0,
        speed: 0,
        heading: 0,
        tamper: 0,
        msisdn: "",
        voltage: 0,
        status: "",
        sats_in_use: 0,
        fix: 0,
        is_valid: 1,
        mode: "",
        sw: "",
        cz: "",
        az: "",
        zh: "",
        hcs: 0,
        hca: 0,
        acceleration_control_enabled: 0,
        speed2_enabled: 0,
        speed3_enabled: 0,
        trottle_bite_point: 0,
        seconds_to_full_speed: 0,
        acceleration_speed_max: 0,
        max_speed: 0,
        created_at: 0,
        updated_at: 0,
        updated_by: 0,
        deleted: 0,
        remaining_time: 0,
        reverse_id: 0,
        gprs: 0,
        zone: null,
        rider: null
    })
    const { imei, partner_id, site_id, mode, updated_at, sats_in_use, speed, status, lat, lng, sw, seconds_to_full_speed, trottle_bite_point, acceleration_speed_max, acceleration_control_enabled, speed2_enabled, speed3_enabled, max_speed, reverse_id, voltage, heading, gprs, zone, rider, remaining_time ,payment_status} = vehicleDetails
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [serviceCall, setServiceCall] = useState(true)
    const [alert, setAlert] = useState("")
    const [alertId, setAlertId] = useState("")
    const [color, setColor] = useState("")
    // const [seconds, setSeconds] = useState("")

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (vehicleId && serviceCall) {
            setLoader(true)
            axios.get("vehicle/" + vehicleId)
                .then((response) => {
                    setVehicleDetails({
                        id: response.data.data.id,
                        imei: response.data.data.imei,
                        partner_id: (response.data.data.partner !== null) ? response.data.data.partner.name : null,
                        site_id: (response.data.data.site !== null) ? response.data.data.site.name : null,
                        zone: (response.data.data.zone !== null) ? response.data.data.zone.zone_type.name : "",
                        lat: response.data.data.lat,
                        lng: response.data.data.lng,
                        speed: response.data.data.speed,
                        status: response.data.data.status,
                        sats_in_use: response.data.data.sats_in_use,
                        mode: response.data.data.mode,
                        sw: response.data.data.sw,
                        acceleration_control_enabled: (response.data.data.acceleration_control_enabled === 1) ? "ENABLED" : "DISABLED",
                        speed2_enabled: (response.data.data.speed2_enabled === 1) ? "ENABLED" : "DISABLED",
                        speed3_enabled: (response.data.data.speed3_enabled === 1) ? "ENABLED" : "DISABLED",
                        trottle_bite_point: response.data.data.trottle_bite_point,
                        seconds_to_full_speed: response.data.data.seconds_to_full_speed,
                        acceleration_speed_max: response.data.data.acceleration_speed_max,
                        max_speed: response.data.data.max_speed,
                        updated_at: response.data.data.updated_at,
                        remaining_time: response.data.data.remaining_time,
                        reverse_id: response.data.data.reverse_id,
                        linked_rider: response.data.data.linked_rider,
                        voltage: response.data.data.voltage,
                        heading: response.data.data.heading,
                        gprs: response.data.data.gprs,
                        rider: (response.data.data.rider !== null) ? response.data.data.rider.name : null,
                        payment_status:response.data.data.payment_status
                    })
                    
                    if(response.data.data.zone_id > 0){
                        setColor(response.data.data.zone.zone_type.color_code)
                    }
                    
                    // setSeconds(response.data.data.time_remaining)
                    setLoader(false)
                    setServiceCall(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [vehicleId, navigate, serviceCall, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])


    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])


    // useEffect(() => {
    //     var myTimeout = null
    //     if (seconds > 0) {
    //         myTimeout = setTimeout(() => setSeconds(seconds - 1), 1000);
    //     } else {
    //         setSeconds('Time Out!');
    //     }
    //     return () => clearTimeout(myTimeout);
    // }, [seconds])


    const cancelHandler = () => {
        if (isNaN(parseInt(partnerId))) {
            navigate('/vehicles-all/' + partnerId)
        } else {
            navigate("/partners-vehicle/" + partnerId)
        }
    }

    const changeStatusHandler = () => {
        setAlert("Are you sure you want to make vehicle available?")
        setAlertId('')
    }

    const alertResponse = (response) => {
        if (response) {
            setLoader(true)
            axios.patch("make_vehicle_availale/" + vehicleId, { status: "available" })
                .then((response) => {
                    setLoader(false)
                    setSuccess(response.data.status.message)
                    setServiceCall(true)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }

        setAlert("")
        setAlertId("")
    }

    return (
        <div>
            {alert ?
                <Alertbox
                    message={alert}
                    alertId={alertId}
                    alertResponse={alertResponse} />
                : null}
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="card">
                <div className="card-body custom-tab">
                    <VehicleTabsHeader active="partners-vehicle-details" parameters={"/" + partnerId + "/" + vehicleId} />
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 mt-3">
                                            <div className="card-header pt-1">
                                                <h5><i className="fa fa-user"></i>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="card-header pt-1 text-end">
                                                {/* {(partnerId > 0) ? */}
                                                <Link to={"/partners-add-vehicle/" + partnerId + "/" + vehicleId}>

                                                    <button className="btn btn-emoby"><i className="fa fa-edit"></i> EDIT VEHICLE</button>
                                                </Link>
                                                {/* : null} */}

                                                {/* <Link to={"/partners-vehicle/" + partnerId}> */}
                                                &nbsp;
                                                <button className="btn btn-emoby" onClick={cancelHandler}><i className="fa fa-edit"></i> BACK</button>
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >ID</label>
                                                <h5>{imei}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0">Time Remaining</label>
                                                <h5>
                                                    {remaining_time > 0 ?
                                                        <VehicleTimer time={remaining_time} />
                                                        : "Time Out!"}
                                                </h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Reverse Id</label>
                                                <h5> {reverse_id} </h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Partner</label>
                                                <h5> {partner_id} </h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Site</label>
                                                <h5>{site_id}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >User</label>
                                                <h5> {rider}</h5>
                                                <hr />
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-1">
                                            <div className="mb-3 vehicles">
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0">
                                                            <i className='fa fa-times fa-lg'></i> &nbsp;&nbsp; User : {rider} </label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <div className="mb-3 vehicles"
                                                style={{
                                                    color: (voltage !== 0) ? "green" : "red",
                                                    borderColor: (voltage !== 0) ? "green" : "red"
                                                }}>
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0" >
                                                            <i 
                                                            className={(voltage !== 0) ?'fa fa-battery-full' : 'fa fa-battery-0'}></i>
                                                            &nbsp;&nbsp; Battery : {voltage}V </label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-1" >
                                            <div className="mb-3 vehicles">
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0">
                                                            <i className='fa fa-wifi fa-lg'></i> &nbsp;&nbsp;   Mode: <b>{mode}</b></label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <div className="mb-3 vehicles">
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0" ><i className='fa fa-hourglass fa-lg'></i> &nbsp;&nbsp; Updated : <b>{updated_at}</b></label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <div className="mb-3 vehicles">
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0" ><i className='fa fa-clone fa-lg'></i> &nbsp;&nbsp; Satellities : <b>{sats_in_use}</b></label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-1">
                                            <div className="mb-3 vehicles"
                                                style={{ color: color ? color : "red", borderColor: color ? color : "red" }}>
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0" ><i className='fa fa-arrows-alt fa-lg'></i> &nbsp;&nbsp; Zone : <b>{zone} &nbsp;Zone</b></label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-1">
                                            <div className="mb-3 vehicles">
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0" ><i className='fa fa-signal fa-lg'></i> &nbsp;&nbsp; GPRS :{gprs} </label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-1">
                                            <div className="mb-3 vehicles">
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <label className="col-form-label pt-0" ><i className='fa fa-tachometer fa-lg'></i> &nbsp;&nbsp; Speed :<b>{speed} km/h</b></label>
                                                    </div>
                                                    <div className='col-sm-2 text-right'>
                                                        <span style={{ float: "right" }}>
                                                            {updated_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Payment Status</label>
                                                <div className='row'>
                                                    <div className='col-md-7'>
                                                        <h5>{payment_status}</h5>
                                                    </div>
                                                    <>
                                                        {(payment_status !== null && payment_status !== "available") ?
                                                            <div className='col-md-5'>
                                                                <button
                                                                    className='btn btn-emoby'
                                                                    onClick={() => changeStatusHandler()}
                                                                >Change status</button>
                                                            </div>
                                                            : null}
                                                    </>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Moving Status</label>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <h5>{status}</h5>
                                                    </div>
                                                    
                                                </div>
                                                <hr />
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Coordinates</label>
                                                <h5> {lat} {lng}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Speed</label>
                                                <h5>{speed}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Version</label>
                                                <h5>{sw}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Direction</label>
                                                <h5>{(heading === 0) ? <i className='fa fa-arrow-down'></i> : <i className='fa fa-arrow-up'></i>}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0"
                                                    style={{ color: (voltage > 20) ? "#32CD32" : "#ff0000" }}>Battery</label>
                                                <h5 style={{ color: (voltage > 20) ? "#32CD32" : "#ff0000" }}>
                                                    {(voltage) ? voltage : "0"}%</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Updated</label>
                                                <h5>{updated_at}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Zone</label>
                                                <h5>{zone} &nbsp;</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Seconds to Full Speed</label>
                                                <h5>{seconds_to_full_speed}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Trottle Bite Point</label>
                                                <h5>{trottle_bite_point}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Acceleration Control Max Speed
                                                </label>
                                                <h5>{acceleration_speed_max}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Acceleration Control
                                                </label>
                                                <h5>{acceleration_control_enabled}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Speed2
                                                </label>
                                                <h5>{speed2_enabled}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Speed3
                                                </label>
                                                <h5>{speed3_enabled}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Max Speed
                                                </label>
                                                <h5>{max_speed}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetails