import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import axios from "../../axios"
import { useNavigate } from 'react-router';
import Loader from "../../components/Loader";
import { ToastContainer, toast } from 'react-toastify';

const ViewRiders = () => {
    const navigate = useNavigate();
    const [riderList, setRiderList] = useState([])
    const [riderListOriginal, setRiderListOriginal] = useState([])
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("")

    useEffect(() => {
        setLoader(true)
        axios.get("riders")
            .then((response) => {
                setRiderList(response.data.data)
                setRiderListOriginal(response.data.data)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false)
                    }
                }
            })
    }, [navigate])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempPartner = [...riderListOriginal].filter(item => (item.name.toLowerCase().includes(searchValue.toLowerCase())));
        setRiderList(tempPartner)
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="card">
                <div className="card-body custom-tab">
                    <div>
                        <div>
                            <div className="card">
                                <div className="card-header p-3">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h5>
                                                <div className="mb-3 m-form__group">
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                                                        <input
                                                            className="form-control" type="text"
                                                            placeholder="Search"
                                                            onChange={(e) => searchHandler(e.target.value)}
                                                            value={search}
                                                        />
                                                    </div>
                                                </div>
                                            </h5>
                                        </div>
                                        <div className="col-sm-8">
                                            <h5 className="add-partners-btn">
                                                {/* <Link to="/add-users">
                                                    <button className="btn btn-emoby float-right" data-bs-original-title="" title=""><i className="fa fa-plus"></i> ADD USER
                                                    </button>
                                                </Link> */}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive" id="customers">
                                    <table className="table table-hover">
                                        <thead className="bg-dark">
                                            <tr>
                                                <th scope="col" className="text-light-custom">NAME</th>
                                                <th scope="col" className="text-light-custom">PARTNER</th>
                                                <th scope="col" className="text-light-custom">SITE</th>
                                                <th scope="col" className="text-light-custom">EMAIL</th>
                                                <th scope="col" className="text-light-custom">UPDATED</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(riderList.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.name}</td>
                                                    <td>{(item.partner !== null) ? item.partner.name : null}</td>
                                                    <td>{(item.site !== null) ? item.site.name : null}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.updated_at}</td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </table>
                                    <div className="p-3">
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center p-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewRiders