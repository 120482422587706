import React from 'react'

const HeaderComponent = (props) => {
    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    return (
        <div className="row">
            {(props.zoneTypesDetails.map((zoneType, index) => (
                <div className="col-sm-3"
                    key={index}
                    style={{
                        height: "250px",
                        backgroundColor: 'rgb(' + hexToRgb(zoneType.color_code).r + ', ' + hexToRgb(zoneType.color_code).g + ', ' + hexToRgb(zoneType.color_code).b + ',0.4)',
                        overflow: "auto",
                        padding: "0",
                        border: '1px solid lightgray'
                    }}>
                    {/* <div style={{ backgroundColor: ((zoneType.name === "Home") ? "#0099E5" : ((zoneType.name === "Allowed") ? "#34BF48" : ((zoneType.name === "Restricted") ? "#FF4C4C" : null))), color: "black", fontWeight: "bold" }}>
                        {zoneType.name}
                    </div> */}
                    <div style={{ backgroundColor: zoneType.color_code, color: "black", fontWeight: "bold", boxShadow: '0px 0px 5px 0px #000', padding: '2px 10px' }}>
                        {zoneType.name}
                    </div>
                    <table>
                        <tbody>
                            {props.mapDetails.map((map, index) => (
                                (zoneType.name === map.zone_type.name) ?
                                    <tr key={index}
                                        onMouseOver={() => props.highlightZone(map.id, map)}
                                        onMouseLeave={() => props.removeHightlightZone(map.id, map)}
                                    >
                                        {
                                            props.HideZones.includes(map.id) ?
                                                <td style={{ cursor: "pointer" }}
                                                    onClick={() => props.RemoveHideZone(map.id)}
                                                    title="Show"
                                                > <i className='fa fa-eye-slash'></i>
                                                </td> : <td style={{ cursor: "pointer" }}
                                                    onClick={() => props.AddHideZone(map.id)}
                                                    title="Hide"
                                                > <i className='fa fa-eye'></i>
                                                </td>
                                        }

                                        <td style={{ width: "550px", padding: '2px 10px', cursor: "pointer" }}
                                            onDoubleClick={() => props.editZoneNameHandler(map.id)}
                                        >
                                            {/* {map.name} */}
                                            {
                                                (props.editZoneName && (props.editNameId === map.id)) ?
                                                    <input
                                                        type="text"
                                                        defaultValue={map.name}
                                                        onKeyDown={(e) => props.saveEditValue(e,map.id)}
                                                        // value={props.inputEditValue}
                                                        onBlur={props.onBlurEvent}
                                                        onChange={(e) => props.setInputEditValue(e.target.value) }
                                                        style={{
                                                            backgroundColor: 'rgb(' + hexToRgb(zoneType.color_code).r + ', ' + hexToRgb(zoneType.color_code).g + ', ' + hexToRgb(zoneType.color_code).b + ',0.4)',
                                                            outlineColor: 'rgb(' + hexToRgb(zoneType.color_code).r + ', ' + hexToRgb(zoneType.color_code).g + ', ' + hexToRgb(zoneType.color_code).b + ',0.4)', border: "none",
                                                            width: "323px"
                                                        }}
                                                    />
                                                    :
                                                    map.name
                                            }
                                        </td>

                                        <td style={{ cursor: "pointer", fontSize: "18px" }}
                                            onClick={() => props.handleZoom(map)}>
                                            <i className='fa fa-search'></i>
                                        </td>

                                        <td style={{ cursor: "pointer", fontSize: "18px", paddingLeft: "10px" }}
                                            onClick={() => props.handleCenter(map)}>
                                            <i className='fa fa-bullseye'></i>
                                        </td>

                                        <td style={{ padding: '2px 10px' }}>
                                            {(zoneType.name === map.zone_type.name) ?
                                                <span
                                                    style={{ cursor: "pointer", fontSize: "18px" }}
                                                    onClick={() => props.deleteHandler(map.id)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </span>
                                                : null}
                                        </td>
                                    </tr>
                                    : null
                            ))}
                        </tbody>
                    </table>
                </div>
            )))}
        </div>
    )
}

export default HeaderComponent