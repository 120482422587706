import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import ProductTabsHeader from './ProductTabsHeader';
import { useParams, useLocation, useNavigate } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';

const ProductDetails = () => {
    const { partnerId } = useParams();
    const { productId } = useParams();
    const location = useLocation();
    const navigate = useNavigate()

    const [productDetails, setProductDetails] = useState({
        name: "",
        partner_id: "",
        title: "",
        system_cost: "",
        partner_cost: "",
        cost: "",
        promotional: "",
        duration: "",
        enabled: ""
    })
    const { name, partner_id, title, system_cost, partner_cost, cost, promotional, duration, enabled } = productDetails;

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (productId) {
            setLoader(true)
            axios.get("partner_product/" + productId)
                .then((response) => {
                    setProductDetails({
                        id: response.data.data.id,
                        partner_id: response.data.data.partner_id,
                        name: response.data.data.name,
                        title: response.data.data.title,
                        system_cost: response.data.data.system_cost,
                        partner_cost: response.data.data.partner_cost,
                        cost: response.data.data.cost,
                        promotional: (response.data.data.promotional === 1) ? "Yes" : "No",
                        duration: response.data.data.duration,
                        enabled: (response.data.data.enabled === 1) ? "Yes" : "No"
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [productId, location, navigate])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])


    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="card">
                <div className="card-body custom-tab">
                    <ProductTabsHeader active="partners-product-details" parameters={"/" + partnerId + "/" + productId} />
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 mt-3">
                                            <div className="card-header pt-1">
                                                <h5><i className="fa fa-user"></i> &nbsp;
                                                    {name}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="card-header pt-1 text-end">
                                                <Link to={"/partners-add-product/" + partnerId + "/" + productId}>
                                                    <button className="btn btn-emoby"><i className="fa fa-edit"></i> EDIT PRODUCT</button>
                                                </Link>&nbsp;
                                                <Link to={"/partners-product/" + partnerId}>
                                                    <button className="btn btn-emoby"> BACK</button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >NAME</label>
                                                <h5>{name}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Partner</label>
                                                <h5>{partner_id}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Title</label>
                                                <h5>{title}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >System Cost</label>
                                                <h5> {system_cost} </h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Partner Cost</label>
                                                <h5>{partner_cost}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" > Cost</label>
                                                <h5>{cost}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Promotional</label>
                                                <h5>{promotional}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Duration(in minutes)</label>
                                                <h5>{duration}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Enabled</label>
                                                <h5>{enabled}</h5>
                                                <hr />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetails