import React from 'react';
import { useNavigate } from 'react-router';

const AddUsers = () => {
    const navigate = useNavigate();

    const cancelHandler = () => {
        navigate(-1)
    }
    return (
        <div>
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form"
                            //  onSubmit={submitPartnersHandler}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i> ADD NEW SITE</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            <button
                                                type="button"
                                                onClick={cancelHandler}
                                                className="btn btn-emoby-secondary"
                                                style={{ marginRight: "10px" }}
                                            >Cancel
                                            </button>

                                            <button className="btn btn-emoby">Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                NAME
                                            </label>

                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter NAME"
                                            // value={name}
                                            // onChange={handleChange} 
                                            />
                                            <small className="form-text text-danger">
                                                {/* {nameError} */}
                                            </small>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUsers