import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Logo from '../images/large-logo.png'
import eLogo from '../images/emoby-logo.png'
import axios from "../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState("");
    const [error, setError] = useState('')

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const submitForm = (e) => {
        e.preventDefault();

        setError('')
        setSuccess('')

        if (email) {
            axios
                .post("send_reset_password_link", { email: email })
                .then((response) => {
                    if (response.data.status.code === 200) {
                        setSuccess(response.data.status.message)
                        setEmail('')
                    }
                })
                .catch((error) => {
                    setError(error.response.data.status.message)
                });
        } else {
            setError('Please enter valid email')
        }
    }

    return (
        <>
            <ToastContainer theme="colored" />
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="login-card">
                            <div>
                                <div>
                                    <div className="logo">
                                        <img className="img-fluid for-light" src={Logo} alt="looginpage" />
                                        <img className="img-fluid for-dark" src={eLogo} alt="looginpage" />
                                    </div>
                                </div>
                                <div className="login-main">
                                    <form className="theme-form" onSubmit={submitForm}>
                                        <h4>Reset Password</h4>
                                        <p>Enter your email to send reset password link</p>
                                        <div className="form-group">
                                            <label className="col-form-label">Email Address</label>
                                            <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="test@gmail.com" />
                                        </div>
                                        <div className="form-group mb-0">
                                            <div className="checkbox p-0">
                                                <input id="checkbox1" type="checkbox" />
                                                <Link to="/login">Got to login</Link>
                                            </div>

                                            <div className="text-end mt-4">
                                                <button className="btn btn-primary btn-block w-100" type="submit">Send link</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
