import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "../../../axios";
import { useNavigate, useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import VehicleTimer from '../../VehiclesManagement/VehicleTimer';

const VehicleComponent = (props) => {
    const partnerId = props.partnerId;
    const location = useLocation();
    const navigate = useNavigate();

    const userDetails = useSelector((state) => state.getUserDetails.user_details);
    const [vehiclesLists, setVehiclesLists] = useState([]);
    const [vehiclesListsOriginal, setVehiclesListsOriginal] = useState([]);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("")
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }

        var partnerId = (typeof props.partnerId === "number") ? props.partnerId : userDetails.partner_id
        var siteId = props.siteId ? props.siteId : userDetails.site_id

        if (partnerId !== undefined && siteId !== undefined) {
            var vehicle_payload = (typeof props.partnerId !== "number") ? "&status=" + (props.partnerId === 'all' ? '' : props.partnerId) : ""

            var url = "vehicles_listing?partner_id=" + partnerId + "&site_id=" + siteId + vehicle_payload
            setLoader(true)
            axios.get(url)
                .then((response) => {
                    setVehiclesLists(response.data.data)
                    setVehiclesListsOriginal(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, location, props.partnerId, props.siteId, userDetails.partner_id, userDetails.site_id])

    useEffect(() => {
        const listing = setInterval(() => {
            var partnerId = (typeof props.partnerId === "number") ? props.partnerId : userDetails.partner_id
            var siteId = props.siteId ? props.siteId : userDetails.site_id
            if (partnerId !== undefined && siteId !== undefined) {
                var vehicle_payload = (typeof props.partnerId !== "number") ? "&status=" + props.partnerId : ""

                var url = "vehicles_listing?partner_id=" + partnerId + "&site_id=" + siteId + vehicle_payload
                axios.get(url)
                    .then((response) => {
                        setVehiclesLists(response.data.data)
                        setVehiclesListsOriginal(response.data.data)
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            }
        }, 10000)
        return () => clearInterval(listing);
    }, [navigate, props.partnerId, props.siteId, userDetails.partner_id, userDetails.site_id])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempVehicle = [...vehiclesListsOriginal].filter(item => (item.imei.toLowerCase().includes(searchValue.toLowerCase())));
        setVehiclesLists(tempVehicle)
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="card">
                <div className="card-header p-3">
                    <div className="row">
                        <div className="col-sm-4">
                            <h5>
                                <div className="mb-3 m-form__group">
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                                        <input
                                            className="form-control" type="text"
                                            placeholder="Search"
                                            onChange={(e) => searchHandler(e.target.value)}
                                            value={search}
                                        />
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="table-responsive" id="customers">
                    <table className="table table-hover">
                        <thead className="bg-dark">
                            <tr>
                                <th scope="col" className="text-light-custom">ID</th>
                                <th scope="col" className="text-light-custom">TIME REMAINING</th>
                                <th scope="col" className="text-light-custom">PARTNER</th>
                                <th scope="col" className="text-light-custom">SITE</th>
                                <th scope="col" className="text-light-custom">USER</th>
                                <th scope="col" className="text-light-custom">STATUS</th>
                                <th scope="col" className="text-light-custom">STATUS</th>
                                <th scope="col" className="text-light-custom">PAYMENT STATUS</th>
                                <th scope="col" className="text-light-custom">VERSION</th>
                                <th scope="col" className="text-light-custom">UPDATED</th>

                            </tr>
                        </thead>
                        <tbody>
                            {vehiclesLists.map((vehicleItem, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={"/partners-vehicle-details/" + partnerId + "/" + vehicleItem.id} style={{ color: "black", textDecoration: "underline" }}>
                                            {vehicleItem.imei}
                                        </Link>
                                    </td>
                                    <td>
                                        <VehicleTimer time={vehicleItem.remaining_time} />
                                    </td>
                                    {/* <td>{vehicleItem.time_remaining}</td> */}
                                    <td>{(vehicleItem.partner !== null) ? vehicleItem.partner.name : null}</td>
                                    <td>{(vehicleItem.site !== null) ? vehicleItem.site.name : null}</td>
                                    <td> {(vehicleItem.rider !== null) ? vehicleItem.rider.name : null} </td>
                                    <td>
                                        <i title="user"
                                            className='fa fa-times'
                                            style={{
                                                color: 'red', 
                                                
                                                opacity: (vehicleItem.updated_since_seconds === 60 || vehicleItem.updated_since_seconds < 60) ? "1": (vehicleItem.updated_since_seconds > 60 && vehicleItem.updated_since_seconds <= 300) ? "0.7" : (vehicleItem.updated_since_seconds > 300 && vehicleItem.updated_since_seconds <= 900) ? "0.5" : "0.3"


                                                // opacity: (vehicleItem.payment_status !== "assigned") ? (vehicleItem.payment_status === "available" && vehicleItem.remaining_time > 300) ? "0.7" :
                                                //     (vehicleItem.payment_status === "available" && vehicleItem.remaining_time > 900) ? "0.5" : "0.3" : "1"
                                                , cursor: "pointer"
                                            }}
                                            data-tip data-for={"user_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor="red"
                                            id={"user_" + vehicleItem.id}>
                                            <span>
                                                <i className='fa fa-times'></i>  &nbsp;
                                                <b>User : {(vehicleItem.rider !== null) ? vehicleItem.rider.name : null} </b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b>{vehicleItem.updated_at}</b> </span>
                                        </ReactTooltip>
                                        &nbsp;&nbsp;


                                        <i title="battery"
                                            className={vehicleItem.voltage !== 0 ? 'fa fa-battery-full' : 'fa fa-battery-0'}
                                            style={{
                                                color: vehicleItem.voltage !== 0 ? 'green' : 'red',
                                                opacity: (vehicleItem.updated_since_seconds === 60 || vehicleItem.updated_since_seconds < 60) ? "1": (vehicleItem.updated_since_seconds > 60 && vehicleItem.updated_since_seconds <= 300) ? "0.7" : (vehicleItem.updated_since_seconds > 300 && vehicleItem.updated_since_seconds <= 900) ? "0.5" : "0.3",
                                                cursor: "pointer"
                                            }}
                                            data-tip data-for={"battery_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor={vehicleItem.voltage !== 0 ? 'green' : 'red'}
                                            id={"battery_" + vehicleItem.id}>
                                            <span>
                                                {vehicleItem.voltage === 0 ?
                                                    <i className='fa fa-battery-0'></i>
                                                    : <i className='fa fa-battery-full'></i>}
                                                &nbsp;
                                                <b>BATTERY:-{vehicleItem.voltage} </b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b>{vehicleItem.updated_at}</b> </span>
                                        </ReactTooltip>&nbsp;&nbsp;

                                        <i
                                            title="satellite"
                                            className='fa fa-clone'
                                            style={{
                                                color: 'red',
                                                opacity: (vehicleItem.updated_since_seconds === 60 || vehicleItem.updated_since_seconds < 60) ? "1": (vehicleItem.updated_since_seconds > 60 && vehicleItem.updated_since_seconds <= 300) ? "0.7" : (vehicleItem.updated_since_seconds > 300 && vehicleItem.updated_since_seconds <= 900) ? "0.5" : "0.3",
                                                cursor: "pointer"
                                            }}
                                            data-tip data-for={"satellite_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor="red"
                                            id={"satellite_" + vehicleItem.id}>
                                            <span>
                                                <i className='fa fa-clone'></i>  &nbsp;
                                                <b>SATELLITES:- {vehicleItem.sats_in_use}</b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b> {vehicleItem.updated_at}</b> </span>
                                        </ReactTooltip>&nbsp;&nbsp;


                                        <i title="zone"
                                            className='fa fa-arrows-alt'
                                            style={{
                                                color: (vehicleItem.zone !== null) ? vehicleItem.zone.zone_type.color_code : "grey",
                                                opacity: (vehicleItem.updated_since_seconds === 60 || vehicleItem.updated_since_seconds < 60) ? "1": (vehicleItem.updated_since_seconds > 60 && vehicleItem.updated_since_seconds <= 300) ? "0.7" : (vehicleItem.updated_since_seconds > 300 && vehicleItem.updated_since_seconds <= 900) ? "0.5" : "0.3",
                                                cursor: "pointer"
                                            }}
                                            data-tip data-for={"zone_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor={(vehicleItem.zone !== null) ? vehicleItem.zone.zone_type.color_code : "maroon"}
                                            id={"zone_" + vehicleItem.id}>
                                            <span>
                                                <i className='fa fa-arrows-alt'></i>  &nbsp;
                                                <b>{(vehicleItem.zone !== null) ? vehicleItem.zone.zone_type.name + " Zone" : "NONE ZONE"} </b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b>{vehicleItem.updated_at}</b> </span>



                                        </ReactTooltip>&nbsp;&nbsp;

                                        <i title="wifi mode"
                                            className='fa fa-wifi'
                                            style={{
                                                color: 'red',
                                                opacity: (vehicleItem.updated_since_seconds === 60 || vehicleItem.updated_since_seconds < 60) ? "1": (vehicleItem.updated_since_seconds > 60 && vehicleItem.updated_since_seconds <= 300) ? "0.7" : (vehicleItem.updated_since_seconds > 300 && vehicleItem.updated_since_seconds <= 900) ? "0.5" : "0.3",
                                                cursor: "pointer"
                                            }}
                                            data-tip data-for={"wifi_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor="red"
                                            id={"wifi_" + vehicleItem.id}>
                                            <span>
                                                <i className='fa fa-wifi'></i>  &nbsp;
                                                <b>Mode:-{vehicleItem.mode} </b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b>{vehicleItem.updated_at}</b> </span>
                                        </ReactTooltip>&nbsp;&nbsp;

                                        <i title="gprs"
                                            className='fa fa-signal'
                                            style={{
                                                color: 'red',
                                                opacity: (vehicleItem.updated_since_seconds === 60 || vehicleItem.updated_since_seconds < 60) ? "1": (vehicleItem.updated_since_seconds > 60 && vehicleItem.updated_since_seconds <= 300) ? "0.7" : (vehicleItem.updated_since_seconds > 300 && vehicleItem.updated_since_seconds <= 900) ? "0.5" : "0.3",
                                                cursor: "pointer"
                                            }}
                                            data-tip data-for={"gprs_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor="red"
                                            id={"gprs_" + vehicleItem.id}>
                                            <span>
                                                <i className='fa fa-signal'></i>  &nbsp;
                                                <b>GPRS: {vehicleItem.gprs} </b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b>{vehicleItem.updated_at}</b> </span>
                                        </ReactTooltip>&nbsp;&nbsp;

                                        <i title="updated"
                                            className='fa fa-hourglass'
                                            style={{
                                                color: 'red',
                                                opacity: (vehicleItem.updated_since_seconds === 60 || vehicleItem.updated_since_seconds < 60) ? "1": (vehicleItem.updated_since_seconds > 60 && vehicleItem.updated_since_seconds <= 300) ? "0.7" : (vehicleItem.updated_since_seconds > 300 && vehicleItem.updated_since_seconds <= 900) ? "0.5" : "0.3",
                                                cursor: "pointer"
                                            }}
                                            data-tip data-for={"updated_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor="red"
                                            id={"updated_" + vehicleItem.id}>
                                            <span>
                                                <i className='fa fa-hourglass'></i>  &nbsp;
                                                <b>UPDATED : {vehicleItem.updated_at}</b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b>{vehicleItem.updated_at}</b> </span>
                                        </ReactTooltip>&nbsp;&nbsp;

                                        <i title="speed"
                                            className='fa fa-tachometer'
                                            style={{ color: 'red', opacity: '0.4', cursor: "pointer" }}
                                            data-tip data-for={"speed_" + vehicleItem.id}></i>
                                        <ReactTooltip
                                            place="top"
                                            backgroundColor="white"
                                            borderColor="red"
                                            textColor="red"
                                            // type="light"
                                            id={"speed_" + vehicleItem.id}>
                                            <span>
                                                <i className='fa fa-tachometer'></i>  &nbsp;
                                                <b>SPEED:- {vehicleItem.speed} &nbsp;km/hr</b>
                                            </span>&nbsp;&nbsp;
                                            <span> <b>{vehicleItem.updated_at}</b> </span>
                                        </ReactTooltip>&nbsp;&nbsp;
                                    </td>
                                    <td>{vehicleItem.status}</td>
                                    <td>{vehicleItem.payment_status}</td>
                                    <td>{vehicleItem.sw}</td>
                                    <td>{vehicleItem.updated_at}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="p-3">
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center p-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleComponent