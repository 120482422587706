import { ActionTypes } from "../constants/action-types";

const initialState = {
    user_type: "",
    partner_id: "",
    site_id: ""
};

export const usertypeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_USERTYPE:
            return { ...state, user_type: payload };

        case ActionTypes.SET_PARTNERID:
            return { ...state, partner_id: payload };

        case ActionTypes.SET_SITEID:
            return { ...state, site_id: payload };

        default:
            return state;
    }
};
