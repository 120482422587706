import React, { useEffect } from 'react'
import { setToken } from "../redux/actions/TokenAction";
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';

const Logout = () => {
    const { logoutFlag } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("tokenAdminEmoby")) {
            localStorage.removeItem("tokenAdminEmoby");
            dispatch(setToken(''));
            if (!logoutFlag) {
                navigate('/login', { state: "Session expired, please login again!!!" })
            }
        }
        return () => {

        };
    }, [dispatch, navigate,logoutFlag])



    return <Navigate to='/login' />

}
export default Logout;