import React, { useState, useEffect } from 'react';
import axios from '../../../../axios'
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../../components/Loader';

const AddWifi = () => {
    const { partnerId } = useParams();
    const { siteId } = useParams();
    const { wifiId } = useParams();
    const navigate = useNavigate();

    const [wifiDetails, setWifiDetails] = useState({
        partner_site_id: siteId || '',
        ssid: "",
        password: ""
    })

    const { ssid, password } = wifiDetails;

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [ssidError, setSsidError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [cpasswordError, setCPasswordError] = useState("")
    const [loader, setLoader] = useState(false)
    const [cpassword, setCPassword] = useState("")

    const handleChange = (e) => {
        setWifiDetails({ ...wifiDetails, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (wifiId) {
            setLoader(true)
            axios.get("partner_site_wifi/" + wifiId)
                .then((response) => {
                    setWifiDetails({
                        id: response.data.data.id,
                        ssid: response.data.data.ssid,
                        password: response.data.data.password
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [wifiId, navigate])

    const validate = () => {
        var flag = true
        setSsidError("")
        setPasswordError("")
        setCPasswordError("")

        if (ssid === '') {
            flag = false;
            setSsidError("SSID field is required")
        }

        if (password === '') {
            flag = false;
            setPasswordError("Password field is required")
        }

        if (password !== cpassword) {
            flag = false;
            setCPasswordError("Confirm Password doesn't match Password")
        }

        return flag;
    }

    const submitWifiHandler = (e) => {
        e.preventDefault();
        const flag = validate()
        if (flag) {
            if (wifiId > 0) {
                setLoader(true)
                axios.put("/partner_site_wifi/" + wifiId, wifiDetails)
                    .then((response) => {
                        navigate("/partners-sites-wifi/" + partnerId + "/" + siteId, { state: response.data.status.message })
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            } else {
                setLoader(true)
                axios.post("/partner_site_wifi", wifiDetails)
                    .then((response) => {
                        navigate("/partners-sites-wifi/" + partnerId + "/" + siteId, { state: response.data.status.message })
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            }
        }

    }


    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])


    const cancelHandler = () => {
        navigate(-1)
    }
    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form"
                                onSubmit={submitWifiHandler}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i> {(wifiId > 0) ? "EDIT" : "ADD NEW"} WIFI</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            <button
                                                type="button"
                                                onClick={cancelHandler}
                                                className="btn btn-emoby-secondary"
                                                style={{ marginRight: "10px" }}
                                            >Cancel
                                            </button>

                                            <button className="btn btn-emoby">Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                SSID
                                            </label>

                                            <input
                                                className="form-control"
                                                id="ssid"
                                                name="ssid"
                                                type="text"
                                                placeholder="Enter SSID"
                                                value={ssid}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {ssidError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >PASSWORD</label>
                                            <input
                                                className="form-control"
                                                id="password"
                                                name="password"
                                                type="password"
                                                placeholder="Enter Password"
                                                value={password}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {passwordError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >CONFIRM PASSWORD</label>
                                            <input
                                                className="form-control"
                                                id="cpassword"
                                                name="cpassword"
                                                type="password"
                                                placeholder="Enter Confirm Password"
                                                value={cpassword}
                                                onChange={(e) => setCPassword(e.target.value)}
                                            />
                                            <small className="form-text text-danger">
                                                {cpasswordError}
                                            </small>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddWifi