import { ActionTypes } from "../constants/action-types";

const initialState = {
    user_details: {}
};

export const userDetailsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case ActionTypes.SET_USER_DETAILS:
        return { ...state, user_details: payload };
  
      default:
        return state;
    }
  };