import React, { useState, useEffect } from 'react'
import Loader from "../../../components/Loader";
import axios from "../../../axios";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Alertbox from '../../../components/Alertbox';

const ViewAdminUsers = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [adminUsersLists, setAdminUSersLists] = useState([])
    const [adminUsersListsOriginal, setAdminUSersListsOriginal] = useState([])
    const [serviceCall, setServiceCall] = useState(true)
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [alertDelete, setAlertDelete] = useState("")
    const [alertIdDelete, setAlertIdDelete] = useState("")
    const [search, setSearch] = useState("")

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (serviceCall) {
            setLoader(true)
            axios.get("admin_users")
                .then((response) => {
                    setAdminUSersLists(response.data.data)
                    setAdminUSersListsOriginal(response.data.data)
                    setLoader(false)
                    setServiceCall(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, location, serviceCall])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const deleteHandler = (getId) => {
        setAlertDelete("Are you sure you want to delete user?")
        setAlertIdDelete(getId);
    }

    const alertResponseDelete = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .delete("admin_user/" + alertId)
                .then((response) => {
                    setSuccess(response.data.status.message);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlertDelete("")
    }

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempPartner = [...adminUsersListsOriginal].filter(item => (item.name.toLowerCase().includes(searchValue.toLowerCase())));
        setAdminUSersLists(tempPartner)
    }


    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="card">
                {alertDelete ?
                    <Alertbox
                        message={alertDelete}
                        alertId={alertIdDelete}
                        alertResponse={alertResponseDelete} />
                    : null}
                <div className="card-header p-3">
                    <div className="row">
                        <div className="col-sm-4">
                            <h5>
                                <div className="mb-3 m-form__group">
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                                        <input
                                            className="form-control" type="text"
                                            placeholder="Search"
                                            onChange={(e) => searchHandler(e.target.value)}
                                            value={search}
                                        />
                                    </div>
                                </div>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="table-responsive" id="customers">
                    <table className="table table-hover">
                        <thead className="bg-dark">
                            <tr>
                                <th scope="col" className="text-light-custom">NAME</th>
                                <th scope="col" className="text-light-custom">PARTNER</th>
                                <th scope="col" className="text-light-custom">SITE</th>
                                <th scope="col" className="text-light-custom">EMAIL</th>
                                <th scope="col" className="text-light-custom">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminUsersLists.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={"/admin-details/" + item.id} style={{ color: "black", textDecoration: "underline" }}>
                                            {item.name}
                                        </Link>
                                    </td>
                                    <td>{item.partner.name}</td>
                                    <td>{item.site !== null ? item.site.name : ''}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        <span
                                            style={{ cursor: "pointer", fontSize: "18px" }}
                                            onClick={() => deleteHandler(item.id)}>
                                            <i className="fa fa-trash"></i>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="p-3">
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center p-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAdminUsers