import React, { useState, useEffect } from 'react'
import SiteTabsHeader from '../SiteTabsHeader'
import { useParams, useNavigate } from 'react-router';
import axios from "../../../../axios";
import { ToastContainer, toast } from 'react-toastify';
import { LoadScript, GoogleMap } from "@react-google-maps/api";
import PanelComponent from './PanelComponent';
import PolygonComponent from './PolygonComponent';
import Alertbox from '../../../../components/Alertbox';
import HeaderComponent from './HeaderComponent';
import VehicleComponentMap from './VehicleComponentMap';
import Loader from '../../../../components/Loader';

const ViewMap = () => {

    const navigate = useNavigate();
    const { partnerId } = useParams();
    const { siteId } = useParams();
    // const map = useGoogleMap()
    // const [ZoneDetails, setZoneDetails] = useState({
    //     partner_site_id: siteId,
    //     name: "Zone_1",
    //     points: [],
    //     zone_type_id: 0
    // })
    // const [path, setPath] = useState([
    //     { lat: 52.52549080781086, lng: 13.398118538856465 },
    //     { lat: 52.48578559055679, lng: 13.36653284549709 },
    //     { lat: 52.48871246221608, lng: 13.44618372440334 }
    // ]);
    // const [path, setPath] = useState([
    //     { lat: -33.928333997799314, lng: 18.458623288840357 },
    //     { lat: -33.98244159744126, lng: 18.427037595480982 },
    //     { lat: -33.978455896041574, lng: 18.50668847438723 },
    //     { lat: -33.9414368889527, lng: 18.503941892355982 }
    // ]);
    // const Rectangle = [
    //     { lat: -33.9667824098526, lng: 18.451756833762232 },
    //     { lat: -33.999805686606706, lng: 18.452443479270045 },
    //     { lat: -34.001798060035675, lng: 18.55269372341067 },
    //     { lat: -33.9667824098526, lng: 18.55372369167239 }
    // ]

    const [mapref, setMapRef] = useState(null);
    const [NewPol, setNewPol] = useState(false);
    const [RecPoint, setRecPoint] = useState([]);
    const [LatCenter, setLatCenter] = useState(-33.947594);
    const [LngCenter, setLngCenter] = useState(18.611929);
    const [path, setPath] = useState();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("")
    const [addPolygon, setAddPolygon] = useState(false)
    const [color_code, setColorCode] = useState('#000000')
    const [serviceCall, setServiceCall] = useState(true)
    const [editPolygon, setEditPolygon] = useState(false)
    const [mapDetails, setMapDetails] = useState([]);
    const [zoneTypesDetails, setZoneTypesDetails] = useState([])
    const [zoneTypeId, setZoneTypeId] = useState("")
    const [alertDelete, setAlertDelete] = useState("")
    const [alertIdDelete, setAlertIdDelete] = useState("")
    const [loader, setLoader] = useState(false)
    const [HideZones, setHideZones] = useState([])

    const [hightlightZoneId, setHightlightZoneId] = useState()
    const [editNameId, seteditNameId] = useState("")
    const [editZoneName, setEditZoneName] = useState(false)
    const [inputEditValue, setInputEditValue] = useState("")
    // const [ZoomZones, setZoomZones] = useState([])
    // const [zoom, setZoom] = useState(8)

    useEffect(() => {
        if (serviceCall) {
            setLoader(true)
            axios.get("partner_site_zones?partner_site_id=" + siteId)
                .then((response) => {
                    setInputEditValue(response.data.data.name)
                    setMapDetails(response.data.data)
                    setLatCenter(response.data.data[0].points[0].lat)
                    setLngCenter(response.data.data[0].points[0].lng)
                    setLoader(false)
                    setServiceCall(false)

                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, siteId, serviceCall])

    // getMasters
    useEffect(() => {
        setLoader(true)
        axios.get("/masters")
            .then((response) => {
                setZoneTypesDetails(response.data.data.zone_types)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false)
                    }
                }
            })
    }, [navigate])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    // HandleZones
    // const handleZone = (id, color) => {
    //     setZoneTypeId(id)
    //     setColorCode(color)
    //     setPath(Rectangle)
    //     setAddPolygon(true)
    //     setEditPolygon(false)
    // }
    const handleZone = (id, color) => {
        setZoneTypeId(id)
        setColorCode(color)
        setPath()
        // setPath(Rectangle)
        setAddPolygon(true)
        setEditPolygon(false)
        setNewPol(true)
    }

    const savePathDetails = () => {
        console.log("saved")
        if (addPolygon) {
            // if (ZoneDetails.points.length > 0) {
            setLoader(true)
            const Addparam = {
                partner_site_id: siteId,
                name: "Zone_1",
                points: path,
                zone_type_id: zoneTypeId
            }
            axios.post("/partner_site_zone", Addparam)
                .then((response) => {
                    setSuccess(response.data.status.message)
                    // setZoneDetails({
                    //     partner_site_id: siteId,
                    //     name: "Zone_1",
                    //     points: [],
                    //     zone_type_id: 0
                    // })
                    setServiceCall(true)
                    setEditPolygon(false)
                    setAddPolygon(false)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
        //     else {
        //         setError("Please create a zone")
        //     }
        // }

        if (editPolygon) {
            for (var i = 0; i < mapDetails.length; i++) {
                const param = {
                    "name": mapDetails[i].name,
                    "points": mapDetails[i].points,
                    "zone_type_id": mapDetails[i].zone_type_id
                }
                const temp = i;
                setLoader(true)
                axios.put("/partner_site_zone/" + mapDetails[i].id, param)
                    .then((response) => {
                        if (temp === mapDetails.length - 1) {
                            setSuccess(response.data.status.message)
                            setEditPolygon(false)
                            setAddPolygon(false)
                            setServiceCall(true)
                            setLoader(false)
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            }
        }
    }

    const EditPathDetails = () => {
        setEditPolygon(true)
        setAddPolygon(false)
    }

    const CloseButton = () => {
        setEditPolygon(false)
        setColorCode('')
        setAddPolygon(false)
        setZoneTypeId("")

        setRecPoint([])
        setNewPol(false)
    }

    const getMapDetails = (index, points) => {
        var temp = JSON.parse(JSON.stringify(mapDetails));
        temp[index].points = points
        if (editPolygon) {
            setMapDetails(temp)
        }
    }

    const newMapDetails = (index, points) => {
        setPath(points)
        // setZoneDetails({ ...ZoneDetails, points: points, zone_type_id: zoneTypeId })
    }

    const deleteHandler = (id) => {
        setAlertDelete("Are you sure you want to delete zone?")
        setAlertIdDelete(id);
    }

    const alertResponseDelete = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .delete("partner_site_zone/" + alertId)
                .then((response) => {
                    setSuccess(response.data.status.message);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlertDelete("")
    }

    const AddHideZone = (id) => {
        var HideZonesTemp = JSON.parse(JSON.stringify(HideZones))
        HideZonesTemp.push(id)
        setHideZones(HideZonesTemp)
    }

    const RemoveHideZone = (id) => {
        var HideZonesTemp = JSON.parse(JSON.stringify(HideZones))
        HideZonesTemp.splice(HideZonesTemp.indexOf(id), 1);
        setHideZones(HideZonesTemp)
    }

    const handleZoom = (mapPoints) => {
        var bounds = new window.google.maps.LatLngBounds()
        for (var i = 0; i < mapPoints.points.length; i++) {
            bounds.extend(mapPoints.points[i]);
        }
        mapref.fitBounds(bounds)


        // console.log(bounds.getCenter().lat(),bounds.getCenter().lng())
        // setLatCenter(bounds.getCenter().lat())
        // setLngCenter(bounds.getCenter().lng())
        // ---------------------------
        // setLatCenter(mapPoints.points[0].lat)
        // setLngCenter(mapPoints.points[0].lng)
        // var pointsArray = mapPoints.points  
        // let result = mapPoints.points.map(a => a.lat)
        // let result1 = mapPoints.points.map(a => a.lng)
        // console.log(result)
        // console.log(result1)
        // var arr = [result, result1]


        // var sum = 0;
        // for (var i = 0, l = arr.length - 1; i < l; i++) {
        //     sum += (arr[i][0] * arr[i + 1][1] - arr[i + 1][0] * arr[i][1]);
        // }

        // setZoom((parseInt(sum / 2) < 13) ? 13 - parseInt(sum / 2) : 13)
        // console.log('The Area of Ploygon is:' + (sum / 2))
        // const bounds = new window.google.maps.LatLngBounds()
        // console.log(bounds)

        // var bounds = new window.google.maps.LatLngBounds()
        // console.log(mapref.fitBounds(bounds))
        // mapref.getZoom()
        // var myMap = new window.google.maps.Map()  
        // console.log(mapref.getBoundsZoomLevel(mapPoints.points.getBounds()))
    }

    // highlight zones
    const highlightZone = (id, mapPoints) => {
        // setLatCenter(mapPoints.points[0].lat)
        // setLngCenter(mapPoints.points[0].lng)
        setHightlightZoneId(id)
        // var hightlightedTemp = JSON.parse(JSON.stringify(hightlightZoneId))
        // hightlightedTemp.push(id)
        // setHightlightZoneId(hightlightedTemp)
    }

    const removeHightlightZone = (id, mapPoints) => {
        // setLatCenter(mapPoints.points[0].lat)
        // setLngCenter(mapPoints.points[0].lng)
        setHightlightZoneId("")
        // var hightlightedTemp = JSON.parse(JSON.stringify(HideZones))
        // hightlightedTemp.splice(hightlightedTemp.indexOf(id), 1);
        // setHightlightZoneId(hightlightedTemp)
    }

    const editZoneNameHandler = (id) => {
        setEditZoneName(true)
        seteditNameId(id)
        // if (editZoneName) {
        //     var ediNameTemp = JSON.parse(JSON.stringify(editNameId))
        //     ediNameTemp.push(id)
        //     seteditNameId(ediNameTemp)
        // }
        // seteditNameId("")
    }

    const handleCenter = (mapPoints) => {
        var bounds = new window.google.maps.LatLngBounds()
        // console.log(bounds)

        for (var i = 0; i < mapPoints.points.length; i++) {
            bounds.extend(mapPoints.points[i]);
        }
        // console.log(bounds.getCenter().lat(),bounds.getCenter().lng())
        setLatCenter(bounds.getCenter().lat())
        setLngCenter(bounds.getCenter().lng())
    }


    const saveEditValue = (e, id) => {
        // setInputEditValue(e.target.value)
        if (e.key === 'Enter') {
            setLoader(true)
            axios.put("/partner_site_zone_name/" + id, { zone_name: inputEditValue })
                .then((response) => {
                    setSuccess(response.data.status.message);
                    setEditZoneName(false)
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }
    
    const onBlurEvent = () => {
        setEditZoneName(false)
    }

    // console.log(inputEditValue)
    return (
        <div className="card">
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            {alertDelete ?
                <Alertbox
                    message={alertDelete}
                    alertId={alertIdDelete}
                    alertResponse={alertResponseDelete} />
                : null}
            <div className="card-body custom-tab">
                <SiteTabsHeader active="partners-sites-map" parameters={"/" + partnerId + "/" + siteId} />

                <HeaderComponent
                    zoneTypesDetails={zoneTypesDetails}
                    mapDetails={mapDetails}
                    deleteHandler={deleteHandler}
                    HideZones={HideZones}
                    AddHideZone={AddHideZone}
                    RemoveHideZone={RemoveHideZone}
                    handleZoom={handleZoom}
                    highlightZone={highlightZone}
                    removeHightlightZone={removeHightlightZone}
                    hightlightZoneId={hightlightZoneId}
                    handleCenter={handleCenter}

                    editZoneName={editZoneName}
                    editNameId={editNameId}
                    editZoneNameHandler={editZoneNameHandler}
                    inputEditValue={inputEditValue}
                    setInputEditValue={setInputEditValue}
                    saveEditValue={saveEditValue}
                    onBlurEvent={onBlurEvent}
                // ZoomZones={ZoomZones}
                />

                <div className="row">
                    <div className="col-md-12" style={{ padding: "5px 0px" }}>
                        <PanelComponent
                            zoneTypeId={zoneTypeId}
                            handleZone={handleZone}
                            savePathDetails={savePathDetails}
                            EditPathDetails={EditPathDetails}
                            CloseButton={CloseButton}
                        />
                        <LoadScript
                            id="script-loader"
                            googleMapsApiKey="AIzaSyAdyM6Ax5B6e6ZPzoWxOBBfY3JPIxeIyMI"
                            language="en"
                            region="us"
                        >
                            <GoogleMap
                                mapContainerClassName="zone-map"
                                center={{ lat: LatCenter, lng: LngCenter }}
                                // center={{ lat: -33.947594, lng: 18.611929 }}
                                zoom={8}
                                version="weekly"
                                onLoad={(map) => {
                                    //                                 alert('load')
                                    //  const bounds = new window.google.maps.LatLngBounds({ lat: 19, lng: 78 });


                                    //                                 // const bounds = new window.google.maps.LatLngBounds();
                                    // //const latLng = new window.google.maps.LatLng(19, 78);

                                    // console.log(bounds.extend(latLng))
                                    // map.fitBounds(bounds);
                                    // var pt = new window.google.maps.LatLng(-33.947594, 18.611929);
                                    // map.setCenter(pt);
                                    // map.setZoom(6);
                                    setMapRef(map);
                                }}
                                onCenterChanged={() => {
                                    if (mapref) {
                                        setLatCenter(mapref.center.lat())
                                        setLngCenter(mapref.center.lng())
                                    }
                                }}
                                onClick={(e) => {
                                    if (NewPol) {
                                        var RecPointTemp = JSON.parse(JSON.stringify(RecPoint))
                                        RecPointTemp.push({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                                        setRecPoint(RecPointTemp)


                                        if (RecPointTemp.length > 2) {
                                            setRecPoint([])
                                            setPath(RecPointTemp)
                                            setNewPol(false)
                                        }
                                    }
                                }}
                            >

                                {
                                    <VehicleComponentMap partnerId={partnerId} siteId={siteId} />
                                }

                                {
                                    addPolygon ?
                                        <PolygonComponent
                                            index={0}
                                            editable={true}
                                            draggable={true}
                                            points={path}
                                            getMapDetails={newMapDetails}
                                            color_code={color_code}
                                        />
                                        : null
                                }

                                {/* <Marker
                                    position={{ lat: -33.947594, lng: 18.611929 }}
                                    defaultTitle="Marker"
                                    label="Hii"
                                    animation="DROP"
                                    shape="square"
                                /> */}


                                {
                                    mapDetails.map((item, index) => {
                                        return (
                                            <PolygonComponent
                                                key={item.id}
                                                index={index}
                                                editable={editPolygon}
                                                draggable={editPolygon}
                                                points={item.points}
                                                getMapDetails={getMapDetails}
                                                color_code={item.zone_type.color_code}
                                                visible={!HideZones.includes(item.id)}
                                                hightlightZoneId={hightlightZoneId === item.id}
                                            // zoom={!ZoomZones.includes(item.id)}
                                            />
                                        )
                                    })
                                }
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default React.memo(ViewMap)