import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useSelector } from "react-redux";

const AddVehicle = () => {
    const userDetails = useSelector((state) => state.getUserDetails.user_details);
    const navigate = useNavigate();
    const { partnerId } = useParams();
    const { vehicleId } = useParams();

    const [vehicleDetails, setVehicleDetails] = useState({
        partner_id: partnerId,
        site_id: "",
        seconds_to_full_speed: "",
        trottle_bite_point: "",
        acceleration_speed_max: "",
        acceleration_control_enabled: "",
        speed2_enabled: "",
        speed3_enabled: "",
        max_speed: ""
    })
    const { partner_id, site_id, seconds_to_full_speed, trottle_bite_point, acceleration_speed_max, acceleration_control_enabled, speed2_enabled, speed3_enabled, max_speed } = vehicleDetails
    const [partnerDetails, setPartnerDetails] = useState([])
    const [siteDetails, setSiteDetails] = useState([])
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)
    const [PartnerDropdown, setPartnerDropdown] = useState("")
    const [SiteDropdown, setSiteDropdown] = useState("")
    const [SecondsFullSpeed, setSecondsFullSpeed] = useState("")
    const [Trottle, setTrottle] = useState("")
    const [AccelerationSpeed, setAccelerationSpeed] = useState("")
    const [MaxSpeed, setMaxSpeed] = useState("")

    const handleChange = (e) => {
        var value = e.target.value
        if (e.target.name === "speed2_enabled" || e.target.name === "speed3_enabled" || e.target.name === "acceleration_control_enabled") {
            value = (e.target.checked === true) ? 1 : 0
        }
        setVehicleDetails({ ...vehicleDetails, [e.target.name]: value });
    }

    useEffect(() => {
        if (vehicleId) {
            setLoader(true)
            axios.get("vehicle/" + vehicleId)
                .then((response) => {
                    setVehicleDetails({
                        id: response.data.data.id,
                        imei: response.data.data.imei,
                        partner_id: response.data.data.partner_id,
                        site_id: response.data.data.site_id,
                        zone_id: response.data.data.zone_id,
                        lat: response.data.data.lat,
                        lng: response.data.data.lng,
                        speed: response.data.data.speed,
                        status: response.data.data.speed,
                        sats_in_use: response.data.data.sats_in_use,
                        mode: response.data.data.mode,
                        sw: response.data.data.sw,
                        acceleration_control_enabled: response.data.data.acceleration_control_enabled,
                        speed2_enabled: response.data.data.speed2_enabled,
                        speed3_enabled: response.data.data.speed3_enabled,
                        trottle_bite_point: response.data.data.trottle_bite_point,
                        seconds_to_full_speed: response.data.data.seconds_to_full_speed,
                        acceleration_speed_max: response.data.data.acceleration_speed_max,
                        max_speed: response.data.data.max_speed,
                        updated_at: response.data.data.updated_at
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }

        return () => {

        }
    }, [vehicleId, navigate])


    useEffect(() => {
        if (userDetails.user_type === 1) {
            setLoader(true)
            axios.get("partners")
                .then((response) => {
                    setPartnerDetails(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, userDetails])


    // const handlePartner = (e) => {
    //     var index = e.nativeEvent.target.selectedIndex
    //     setVehicleDetails({ ...vehicleDetails, partner_id: index })
    //     handleSite(index)
    // }

    // const handleSite = (getPartner) => {
    //     // var partnerID = partner_id  ? partner_id : getPartner
    //     // console.log(partnerID)

    //     // var url = "partner_sites"
    //     // if (partner_id > 0) {
    //     //     url += "?partner_id=" + partner_id
    //     // }
    //     setLoader(true)
    //     axios.get("partner_sites?partner_id=" + getPartner)
    //         .then((response) => {
    //             setSiteDetails(response.data.data)
    //             setLoader(false)
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 if (error.response.status === 401) {
    //                     navigate('/logout')
    //                 } else if (error.response.status === 402) {
    //                     navigate('/unauthorized')
    //                 } else if (error.response || error.response.status !== 200) {
    //                     setError(error.response.data.status.message);
    //                     setLoader(false)
    //                 }
    //             }
    //         })
    // }

    useEffect(() => {
        if (partner_id > 0) {
            setLoader(true)
            var url = "partner_sites?partner_id=" + partner_id
            axios.get(url)
                .then((response) => {
                    setSiteDetails(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, partner_id])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const validate = () => {
        var flag = true;
        setPartnerDropdown("")
        setSiteDropdown("")
        setSecondsFullSpeed("")
        setTrottle("")
        setAccelerationSpeed("")
        setMaxSpeed("")

        if (partner_id === "") {
            flag = false;
            setPartnerDropdown("Partner field is required")
        }
        if (site_id === "") {
            flag = false;
            setSiteDropdown("Site field is required")
        }
        if (seconds_to_full_speed === "") {
            flag = false;
            setSecondsFullSpeed("Seconds to full speed field is required")
        }
        if (trottle_bite_point === "") {
            flag = false;
            setTrottle("Trottle bite point field is required")
        }
        if (acceleration_speed_max === "") {
            flag = false;
            setAccelerationSpeed("Acceleration speed field is required")
        }
        if (max_speed === "") {
            flag = false;
            setMaxSpeed("Max speed field is required")
        }

        return flag
    }

    const submitVehicleDetails = (e) => {
        e.preventDefault();
        const flag = validate();
        if (flag) {
            setLoader(true);
            axios.put("vehicle/" + vehicleId, vehicleDetails)
                .then((response) => {
                    navigate("/partners-vehicle-details/" + partnerId + "/" + vehicleId, { state: response.data.status.message })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }

    const cancelHandler = () => {
        navigate(-1)
    }
    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form"
                                onSubmit={submitVehicleDetails}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i> EDIT VEHICLE</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            <button
                                                type="button"
                                                onClick={cancelHandler}
                                                className="btn btn-emoby-secondary"
                                                style={{ marginRight: "10px" }}
                                            >Cancel
                                            </button>

                                            <button className="btn btn-emoby">Submit</button>
                                        </div>
                                    </div>
                                    {(userDetails.user_type === 1) ?
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Partner</label>
                                                <select
                                                    className="form-select digits" id="partner_id"
                                                    name='partner_id'
                                                    value={partner_id}
                                                    onChange={handleChange}
                                                // onChange={handlePartner}
                                                >
                                                    <option value="">Choose Partner</option>
                                                    {partnerDetails.map((item) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <small className="form-text text-danger">
                                                    {PartnerDropdown}
                                                </small>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Site</label>
                                            <select
                                                className="form-select digits"
                                                id="site_id"
                                                name='site_id'
                                                value={site_id}
                                                onChange={handleChange}
                                            >
                                                <option>Choose Site</option>
                                                {
                                                    siteDetails.map((item) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            <small className="form-text text-danger">
                                                {SiteDropdown}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Seconds to Full Speed
                                            </label>
                                            <input
                                                className="form-control"
                                                id="seconds_to_full_speed"
                                                name="seconds_to_full_speed"
                                                type="text"
                                                placeholder="Enter seconds to full speed"
                                                value={seconds_to_full_speed}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {SecondsFullSpeed}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Trottle Bite Point
                                            </label>
                                            <input
                                                className="form-control"
                                                id="trottle_bite_point"
                                                name="trottle_bite_point"
                                                type="text"
                                                placeholder="Enter Trottle bite point"
                                                value={trottle_bite_point}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {Trottle}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Acceleration Control Max Speed
                                            </label>
                                            <input
                                                className="form-control"
                                                id="acceleration_speed_max"
                                                name="acceleration_speed_max"
                                                type="text"
                                                placeholder="Enter Acceleration speed max"
                                                value={acceleration_speed_max}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {AccelerationSpeed}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mt-1">
                                            <label className="col-form-label">Acceleration Control</label>
                                            <input
                                                className="form-control form-check-input"
                                                id="acceleration_control_enabled" name="acceleration_control_enabled"
                                                type="checkbox"
                                                style={{ minWidth: "45px", minHeight: "45px" }}
                                                value="1"
                                                checked={(acceleration_control_enabled === 1) ? true : false}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0">Speed2</label>
                                            <input
                                                className="form-control form-check-input m-0"
                                                id="speed2_enabled"
                                                name="speed2_enabled"
                                                type="checkbox"
                                                style={{ minWidth: "45px", minHeight: "45px" }}
                                                value="1"
                                                checked={(speed2_enabled === 1) ? true : false}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0">Speed3</label>
                                            <input
                                                className="form-control form-check-input m-0"
                                                id="speed3_enabled"
                                                name="speed3_enabled"
                                                type="checkbox"
                                                style={{ minWidth: "45px", minHeight: "45px" }}
                                                value="1"
                                                checked={(speed3_enabled === 1) ? true : false}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Max Speed

                                            </label>
                                            <input
                                                className="form-control"
                                                id="max_speed"
                                                name="max_speed"
                                                type="text"
                                                placeholder="Enter Max speed"
                                                value={max_speed}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {MaxSpeed}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddVehicle