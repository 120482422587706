import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import axios from "../../../axios"
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router';
import Loader from '../../../components/Loader';

const AddProduct = () => {
    const { partnerId } = useParams();
    const { productId } = useParams();
    const navigate = useNavigate();

    const [productDetails, setProductDetails] = useState({
        name: "",
        partner_id: partnerId || '',
        title: "",
        system_cost: "",
        partner_cost: "",
        cost: "",
        promotional: 0,
        duration: "",
        enabled: 0
    })

    const { name, title, system_cost, partner_cost, cost, promotional, duration, enabled } = productDetails;

    const [nameError, setNameError] = useState("")
    // const [partnerError, setPartnerError] = useState("")
    const [titleError, setTitleError] = useState("")
    const [systemCostError, setSystemCostError] = useState("")
    const [partnerCostError, setPartnerCostError] = useState("")
    const [costError, setCostError] = useState("")
    const [promotionalError, setPromotionalError] = useState("")
    const [durationError, setDurationError] = useState("")
    const [enabledError, setEnabledError] = useState("")
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        var value = e.target.value
        if (e.target.name === "promotional" || e.target.name === "enabled") {
            value = (e.target.checked === true) ? 1 : 0
        }
        setProductDetails({ ...productDetails, [e.target.name]: value });
    }

    useEffect(() => {
        if (productId) {
            setLoader(true)
            axios.get("partner_product/" + productId)
                .then((response) => {
                    setProductDetails({
                        id: response.data.data.id,
                        partner_id: response.data.data.partner_id,
                        name: response.data.data.name,
                        title: response.data.data.title,
                        system_cost: response.data.data.system_cost,
                        partner_cost: response.data.data.partner_cost,
                        cost: response.data.data.cost,
                        promotional: response.data.data.promotional,
                        duration: response.data.data.duration,
                        enabled: response.data.data.enabled
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [productId, navigate])


    const validate = () => {
        var flag = true
        setNameError("")
        // setPartnerError("")
        setTitleError("")
        setSystemCostError("")
        setPartnerCostError("")
        setCostError("")
        setPromotionalError("")
        setDurationError("")
        setEnabledError("")

        if (name === '') {
            flag = false;
            setNameError("Name field is required")
        }

        var check = /^[0-9\b]+$/;
        if (system_cost === '') {
            flag = false;
            setSystemCostError("System Cost field is required")
        } else if (!check.test(system_cost)) {
            flag = false;
            setSystemCostError("System Cost field allow only numeric")
        }

        if (title === '') {
            flag = false;
            setTitleError("Title field is required")
        }

        var pattern = /^[0-9\b]+$/;
        if (partner_cost === '') {
            flag = false;
            setPartnerCostError("Partner Cost field is required")
        } else if (!pattern.test(partner_cost)) {
            flag = false;
            setPartnerCostError("Partner Cost field allow only numeric")
        }

        if (cost === '') {
            flag = false;
            setCostError("Cost field is required")
        } else if (!check.test(cost)) {
            flag = false;
            setCostError("Cost field allow only numeric")
        }

        // if (promotional === 0) {
        //     flag = false;
        //     setPromotionalError("Promotional field is required")
        // }

        if (duration === '') {
            flag = false;
            setDurationError("Duration field is required")
        } else if (!check.test(duration)) {
            flag = false;
            setDurationError("Duration field allow only numeric")
        }

        // if (enabled === 0) {
        //     flag = false;
        //     setEnabledError("Enabled field is required")
        // }

        return flag;
    }

    const submitProductHandler = (e) => {
        e.preventDefault();
        const flag = validate()
        if (flag) {
            if (productId > 0) {
                setLoader(true)
                axios.put("/partner_product/" + productId, productDetails)
                    .then((response) => {
                        navigate('/partners-product-details/' + partnerId + "/" + productId, { state: response.data.status.message })
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            } else {
                setLoader(true)
                axios.post("/partner_product", productDetails)
                    .then((response) => {
                        navigate("/partners-product/" + partnerId, { state: response.data.status.message })
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            }
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const cancelHandler = () => {
        navigate(-1)
    }
    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form"
                                onSubmit={submitProductHandler}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i>
                                                {(productId > 0) ? "EDIT" : "ADD NEW"}  PRODUCT</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            <button
                                                type="button"
                                                onClick={cancelHandler}
                                                className="btn btn-emoby-secondary"
                                                style={{ marginRight: "10px" }}
                                            >Cancel
                                            </button>

                                            <button className="btn btn-emoby">Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                NAME
                                            </label>

                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter Full Name"
                                                value={name}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {nameError}
                                            </small>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Partner</label>
                                            <select
                                                className="form-select digits" id="partner_id"
                                                name='partner_id'
                                                value={partner_id}
                                                onChange={handleChange}
                                            >
                                                <option value="1">option-1</option>
                                                <option value="2">option-2</option>
                                                <option value="3">option-3</option>
                                                <option value="4">option-4</option>
                                                <option value="5">option-5</option>
                                            </select>
                                            <small className="form-text text-danger">
                                                {partnerError}
                                            </small>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Title
                                            </label>
                                            <input
                                                className="form-control"
                                                id="title"
                                                name="title"
                                                type="text"
                                                placeholder="Enter Title"
                                                value={title}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {titleError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                System Cost
                                            </label>
                                            <input
                                                className="form-control"
                                                name="system_cost"
                                                id="system_cost"
                                                type="text"
                                                placeholder="Enter System Cost"
                                                value={system_cost}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {systemCostError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Partner Cost
                                            </label>
                                            <input
                                                className="form-control"
                                                name="partner_cost"
                                                id="partner_cost"
                                                type="text"
                                                placeholder="Enter Partner Cost"
                                                value={partner_cost}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {partnerCostError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Cost
                                            </label>
                                            <input
                                                className="form-control"
                                                id="cost"
                                                name="cost"
                                                type="text"
                                                placeholder="Enter Cost"
                                                value={cost}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {costError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="col-form-label pt-0">Promotional</label>
                                                    <input
                                                        className="form-control form-check-input m-0"
                                                        id="promotional"
                                                        name="promotional" type="checkbox"
                                                        style={{ minWidth: "45px", minHeight: "45px" }}
                                                        value={promotional}
                                                        checked={(promotional === 1) ? true : false}
                                                        onChange={handleChange} />
                                                    <small className="form-text text-danger">
                                                        {promotionalError}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >
                                                    Promotional
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="promotional"
                                                    id="promotional"
                                                    type="text"
                                                    placeholder="Enter Promotional"
                                                    value={promotional}
                                                    onChange={handleChange}
                                                />
                                                <small className="form-text text-danger">
                                                    {promotionalError}
                                                </small>
                                            </div>
                                        </div> */}

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Duration (In minutes)
                                            </label>
                                            <input
                                                className="form-control" type="text"
                                                name="duration"
                                                id="duration"
                                                placeholder="Enter Duration"
                                                value={duration}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {durationError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="col-form-label pt-0">Enabled</label>
                                                    <input
                                                        className="form-control form-check-input m-0"
                                                        id="enabled"
                                                        name="enabled" type="checkbox"
                                                        style={{ minWidth: "45px", minHeight: "45px" }}
                                                        value={enabled}
                                                        checked={(enabled === 1) ? true : false}
                                                        onChange={handleChange} />
                                                    <small className="form-text text-danger">
                                                        {enabledError}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddProduct