import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { OverlayView, Marker } from "@react-google-maps/api";
// import { OverlayView, Marker,InfoWindow } from "@react-google-maps/api";
import axios from '../../../../axios'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const VehicleComponentMap = (props) => {
	const userDetails = useSelector((state) => state.getUserDetails.user_details);
	const navigate = useNavigate();

	const [vehicleLists, setVehicleLists] = useState([])
	const [error, setError] = useState("")
	const [selectedId, setSelectedId] = useState("")
	const imei = props.imei;

	useEffect(() => {
		// console.log("vehicle",props.partnerId,props.siteId)
		var partnerId = props.partnerId ? props.partnerId : userDetails.partner_id
		var siteId = props.siteId ? props.siteId : userDetails.site_id
		if (partnerId !== undefined && siteId !== undefined) {
			axios.get("vehicles_listing?partner_id=" + partnerId + "&site_id=" + siteId)
				.then((response) => {
					setVehicleLists(response.data.data)
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 401) {
							navigate('/logout')
						} else if (error.response.status === 402) {
							navigate('/unauthorized')
						} else if (error.response || error.response.status !== 200) {
							setError(error.response.data.message);
						}
					}
				})
		}
	}, [navigate, props.partnerId, userDetails.partner_id, props.siteId, userDetails.site_id])

	// Vehicle Listing
	useEffect(() => {
		const listing = setInterval(() => {
			var partnerId = props.partnerId ? props.partnerId : userDetails.partner_id
			var siteId = props.siteId ? props.siteId : userDetails.site_id
			if (partnerId !== undefined && siteId !== undefined) {
				axios.get("vehicles_listing?partner_id=" + partnerId + "&site_id=" + siteId)
					.then((response) => {
						setVehicleLists(response.data.data)
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.status === 401) {
								navigate('/logout')
							} else if (error.response.status === 402) {
								navigate('/unauthorized')
							} else if (error.response || error.response.status !== 200) {
								setError(error.response.data.message);
							}
						}
					})
			}
		}, 10000)
		return () => clearInterval(listing);
	}, [navigate, props.partnerId, userDetails.partner_id, props.siteId, userDetails.site_id])

	useEffect(() => {
		if (error !== '') {
			toast.error(error, '', 5000);
			setError('')
		}
	}, [error])

	// const dummyAreas = [
	// 	{ lat: 19.974371960674315, lng: 73.7861141182534 },
	// 	{ lat: 19.97353137613223, lng: 73.78571818400464 },
	// 	{ lat: 19.975081706936983, lng: 73.79046837629528 }
	// ]

	const handleClicked = (id) => {
		setSelectedId(id)
	}

	return (
		<div>
			{vehicleLists.map((item, index) => (
				// <Marker
				// 	key={index}
				// 	position={{
				// 		lat: item.lat,
				// 		lng: item.lng
				// 	}}
				// 	// label={{
				// 	// 	color: "red",
				// 	// 	fontSize: "15px",
				// 	// 	fontWeight: "bold"
				// 	// }}
				// 	opacity={1}
				// 	icon={(imei === item.imei) ? "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue%23.png" : "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red.png"}
				// >
				// 	<InfoWindow
				// 		position={{
				// 			lat: item.lat,
				// 			lng: item.lng
				// 		}}
				// 		>
				// 		<div className={(selectedId === item.id) ? "test-clicked" : ""}>
				// 			<div
				// 				onClick={() => handleClicked(item.id)}
				// 				className={(imei === item.imei) ? 'selected-test' : 'test'}>
				// 				{item.imei}
				// 			</div>
				// 		</div>
				// 	</InfoWindow>
				// </Marker>
				<Marker
					key={index}
					position={{
						lat: item.lat,
						lng: item.lng
					}}
					opacity={0}
					icon={(imei === item.imei) ? "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue%23.png" : "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red.png"}
				>
					<OverlayView
						key={index}
						position={{
							lat: item.lat,
							lng: item.lng
						}}
						mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
						getPixelPositionOffset={(width, height) => ({
							x: -(width / 2),
							y: -(height / 2),
						})}
					>
						<div className={(selectedId === item.id) ? "marker-clicked marker-hover" : "marker-hover"}>
							<div
								className={(imei === item.imei) ? 'selected-marker-in' : 'marker-in'}
								onClick={() => handleClicked(item.id)}>{item.imei}</div>
							<span className={(imei === item.imei) ? 'selected-marker-in-pointer' : 'marker-in-pointer'}></span>
						</div>
					</OverlayView>
				</Marker>

				// <OverlayView
				// 	key={index}
				// 	position={{
				// 		lat: item.lat,
				// 		lng: item.lng
				// 	}}
				// 	bounds={{
				// 		ne: { lat: item.lat, lng: item.lng },
				// 		sw: { lat: item.lat, lng: item.lng }
				// 	}}
				// 	mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
				// 	getPixelPositionOffset={(width, height) => ({
				// 		x: -(width / 2),
				// 		y: -(height / 2),
				// 	})}
				// >
				// 	{/* <div>{item.imei}</div> */}
				// 	<div className={(selectedId === item.id) ? "marker-clicked" : ""}>
				// 		<div
				// 			className={(imei === item.imei) ? 'selected-marker-in' : 'marker-in'}
				// 			onClick={() => handleClicked(item.id)}>{item.imei}</div>
				// 		<span className={(imei === item.imei) ? 'selected-marker-in-pointer' : 'marker-in-pointer'}></span>
				// 	</div>
				// </OverlayView>
			))
			}

			{/* {dummyAreas.map((item,index) => (
				<OverlayView
					key={index}
					position={{
						lat: item.lat,
						lng: item.lng
					}}

					mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
					getPixelPositionOffset={(width, height) => ({
						x: -(width / 2),
						y: -(height / 2),
					})}>
					<>
						<div className='selected-callout-box'>abc</div>
						<span className='selected-callout-pointer'></span>
					</>
				</OverlayView>
			))} */}
		</div >
	)
}

export default VehicleComponentMap