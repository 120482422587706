import React from 'react'
import PartnerTabsHeader from '../PartnerTabsHeader';
import { useParams } from 'react-router-dom';
import VehicleComponent from './VehicleComponent';

const ViewVehicles = () => {
    const { partnerId } = useParams();

    return (
        <div className="card">
            <div className="card-body custom-tab">
                <PartnerTabsHeader active="partners-vehicle" parameters={"/" + partnerId} />
                <div>
                    <VehicleComponent partnerId={parseInt(partnerId)} />
                </div>
            </div>
        </div>
    )
}

export default ViewVehicles