import React, { useState, useEffect } from 'react';
import axios from "../../axios"
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useNavigate, useLocation } from 'react-router';

const ViewCashPayment = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [cashPaymentList, setCashPaymentList] = useState([])
    const [cashPaymentListOriginal, setCashPaymentListOriginal] = useState([])
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("")
    const [loader, setLoader] = useState(false)
    const [serviceCall, setServiceCall] = useState(true)

    useEffect(() => {
        if (serviceCall) {
            setLoader(true)
            axios.get("cashpayments")
                .then((response) => {
                    setCashPaymentList(response.data.data)
                    setCashPaymentListOriginal(response.data.data)
                    setLoader(false)
                    setServiceCall(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, serviceCall])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])


    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempPartner = [...cashPaymentListOriginal].filter(item => (item.unique_number.toLowerCase().includes(searchValue.toLowerCase())));
        setCashPaymentList(tempPartner)
    }

    const requestHandler = (getID, getStatus) => {
        setLoader(true)
        axios.patch("cashpayment_confirmation/" + getID, { status: getStatus })
            .then((response) => {
                setSuccess(response.data.status.message)
                setServiceCall(true)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false)
                    }
                }
            })
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="card">
                <div className="card-header p-3">
                    <div className="row">
                        <div className="col-sm-4">
                            <h5>
                                <div className="mb-3 m-form__group">
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                                        <input
                                            className="form-control" type="text"
                                            placeholder="Search"
                                            onChange={(e) => searchHandler(e.target.value)}
                                            value={search}
                                        />
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div className="col-sm-8">
                            <h5 className="add-partners-btn">
                                {/* <Link to={"/partners-add-sites/" + partnerId}>
                                    <button className="btn btn-emoby float-right" data-bs-original-title="" title=""><i className="fa fa-plus"></i> ADD SITE
                                    </button>
                                </Link> */}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="table-responsive" id="customers">
                    <table className="table table-hover">
                        <thead className="bg-dark">
                            <tr>
                                <th scope="col" className="text-light-custom">CODE</th>
                                <th scope="col" className="text-light-custom">CUSTOMER</th>
                                <th scope="col" className="text-light-custom">VEHICLE</th>
                                <th scope="col" className="text-light-custom">DURATION (In minutes)</th>
                                <th scope="col" className="text-light-custom">PRODUCT</th>
                                <th scope="col" className="text-light-custom">STATUS</th>
                                <th scope="col" className="text-light-custom">COST</th>
                                {/* <th scope="col" className="text-light-custom">REQUESTED</th>
                                <th scope="col" className="text-light-custom">APPROVE</th>
                                <th scope="col" className="text-light-custom">REJECT</th> */}
                                <th scope="col" className="text-light-custom">ACTION</th>
                                <th scope="col" className="text-light-custom">LICENSE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cashPaymentList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.unique_number}</td>
                                    <td>{(item.rider) ? item.rider.name : null}</td>
                                    <td>{item.imei}</td>
                                    <td>{item.duration}</td>
                                    <td>{(item.product) ? item.product.name : null}</td>
                                    <td>{(item.status === 2) ? "Approved" : (item.status === 3) ? "Rejected" : "Pending"}</td>
                                    <td>{item.cost}</td>
                                    <td>
                                        {(item.status === 1) ?
                                            <>
                                                <button className='btn btn-success' onClick={() => requestHandler(item.id, 2)}>
                                                    <i className='fa fa-thumbs-up'></i>
                                                </button>
                                                &nbsp;
                                                <button className='btn btn-danger' onClick={() => requestHandler(item.id, 3)}>
                                                    <i className='fa fa-thumbs-down'></i>
                                                </button>
                                            </>
                                            : null}
                                    </td>
                                    <td>
                                        {(item.rider.driving_licence) ?
                                            <a
                                                href={item.rider.driving_licence}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="btn btn-emoby">
                                                View
                                            </a>
                                            : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="p-3">
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center p-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ViewCashPayment