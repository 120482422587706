import React, { useState, useEffect } from 'react'

const VehicleTimer = (props) => {
  const [seconds, setSeconds] = useState(parseInt(props.time))

  useEffect(() => {
    var myTimeout = null
    if (seconds > 0) {
      myTimeout = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds('Time Out!');
    }
    return () => clearTimeout(myTimeout);
  }, [seconds])


  return (
    <div>
      {seconds}
    </div>
  )
}

export default VehicleTimer