import React, { useState, useEffect } from 'react';
import Alertbox from '../../../components/Alertbox';
import axios from "../../../axios";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import Loader from "../../../components/Loader";

const ViewPartners = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [PartnersList, setPartnersList] = useState([])
    const [PartnersListOriginal, setPartnersListOriginal] = useState([])
    const [serviceCall, setServiceCall] = useState(true)
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    // const [alert, setAlert] = useState("")
    // const [alertId, setAlertId] = useState("")
    const [alertDelete, setAlertDelete] = useState("")
    const [alertIdDelete, setAlertIdDelete] = useState("")
    const [search, setSearch] = useState("")
    // const [id, setid] = useState("")

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (serviceCall) {
            setLoader(true)
            axios.get('partners')
                .then((response) => {
                    setPartnersList(response.data.data)
                    setPartnersListOriginal(response.data.data)
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [serviceCall, location, navigate])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const deleteHandler = (id) => {
        setAlertDelete("Are you sure you want to delete partner?")
        setAlertIdDelete(id);
    }

    // const statusHandler = (id, active) => {
    //     setAlert("Are you sure you want to change the status?")
    //     setAlertId(active);
    //     setid(id);
    // }

    // const alertResponse = (response, type, alertId) => {
    //     if (response) {
    //         axios
    //             .patch("partner/status/" + id, { active: alertId })
    //             .then((response) => {
    //                 setSuccess(response.data.status.message);
    //                 setServiceCall(true);
    //             })
    //             .catch((error) => {
    //                 if (error.response) {
    //                     if (error.response.status === 401) {
    //                         navigate('/logout')
    //                     } else if (error.response || error.response.status !== 200) {
    //                         setError(error.response.data.message);
    //                     }
    //                 }
    //             });
    //     }
    //     setAlert("")
    // }

    const alertResponseDelete = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .delete("partner/" + alertId)
                .then((response) => {
                    setSuccess(response.data.status.message);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlertDelete("")
    }

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempPartner = [...PartnersListOriginal].filter(item => (item.name.toLowerCase().includes(searchValue.toLowerCase())));
        setPartnersList(tempPartner)
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            {alertDelete ?
                <Alertbox
                    message={alertDelete}
                    alertId={alertIdDelete}
                    alertResponse={alertResponseDelete} />
                : null}
            <div className="card">
                <div className="card-header p-3">
                    <div className="row">
                        <div className="col-sm-4">
                            <h5>
                                <div className="mb-3 m-form__group">
                                    <div className="input-group">
                                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                                        <input
                                            className="form-control" type="text"
                                            placeholder="Search"
                                            onChange={(e) => searchHandler(e.target.value)}
                                            value={search}
                                        />
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div className="col-sm-8">
                            <h5 className="add-partners-btn">
                                <Link to="/add-partners">                              <button className="btn btn-emoby float-right" data-bs-original-title="" title=""><i className="fa fa-plus"></i> ADD PARTNER
                                </button>
                                </Link>
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="table-responsive" id="customers">
                    <table className="table table-hover">
                        <thead className="bg-dark">
                            <tr>
                                <th scope="col" className="text-light-custom">NAME</th>
                                {/* <th scope="col" className="text-light-custom">PARTNER</th> */}
                                <th scope="col" className="text-light-custom">CONTACT PERSON</th>
                                <th scope="col" className="text-light-custom">CONTACT NUMBER</th>
                                <th scope="col" className="text-light-custom">EMAIL</th>
                                <th scope="col" className="text-light-custom">ADDRESS</th>
                                <th scope="col" className="text-light-custom">CITY</th>
                                <th scope="col" className="text-light-custom">SUBURB</th>
                                <th scope="col" className="text-light-custom">PROVINCE</th>
                                <th scope="col" className="text-light-custom">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {PartnersList.map((partner, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link to={"/partners-details/" + partner.id} style={{ color: "black", textDecoration: "underline" }}>
                                            {partner.name}
                                        </Link>
                                    </td>
                                    {/* <td>{partner.partner_id}</td> */}
                                    <td>{partner.contact_person}</td>
                                    <td>{partner.contact_number}</td>
                                    <td>{partner.email}</td>
                                    <td>{partner.address}</td>
                                    <td>{partner.city}</td>
                                    <td>{partner.suburb}</td>
                                    <td>{partner.province !== null ? partner.province.name : ''}</td>
                                    <td>
                                        {/* <Link to={"/add-partners/" + partner.id} style={{ color: "black" }}>
                                                            <span
                                                                style={{ cursor: "pointer", fontSize: "18px" }}>
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                        </Link>
                                                        &nbsp; */}

                                        <span
                                            style={{ cursor: "pointer", fontSize: "18px" }}
                                            onClick={() => deleteHandler(partner.id, partner.active)}>
                                            <i className="fa fa-trash"></i>
                                        </span> &nbsp;

                                        {/* <span
                                            style={{ cursor: "pointer", fontSize: "18px" }}
                                            onClick={() => statusHandler(partner.id, partner.active)}>
                                            <i className="fa fa-refresh"></i>
                                        </span> &nbsp;

                                        <span
                                            style={{ cursor: "pointer", fontSize: "18px" }}>
                                            <i className="fa fa-gear"></i>
                                        </span> &nbsp; */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="p-3">
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center p-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewPartners;