import React from 'react'
import { Link } from 'react-router-dom';

const VehicleTabsHeader = (props) => {
    return (
        <div>
            <ul className="nav nav-tabs border-tab nav-primary">
                <li className="nav-item">
                    <Link to={"/partners-vehicle-details" + props.parameters} className={"nav-link " + (props.active === 'partners-vehicle-details' ? 'active' : '')}>
                        Details
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/partners-vehicle-qrcode" + props.parameters}
                        className={"nav-link " + (props.active === 'partners-vehicle-qrcode' ? 'active' : '')}>
                        QR Code
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/partners-vehicle-map" + props.parameters} className={"nav-link " + (props.active === 'partners-vehicle-map' ? 'active' : '')}>
                        Map
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default VehicleTabsHeader