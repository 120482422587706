import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';

const AddSites = () => {
    const navigate = useNavigate();
    const cancelHandler = () => {
        navigate(-1)
    }
    const { partnerId } = useParams();
    const { siteId } = useParams();
    const [provinceList, setProvinceList] = useState([])

    const [siteDetails, setSiteDetails] = useState({
        id: siteId || '',
        partner_id: partnerId,
        name: "",
        contact_number: "",
        contact_person: "",
        email: "",
        accept_cash_payments: 0,
        accept_card_payments: 0,
        address: "",
        city: "",
        suburb: "",
        province_id: "",
    });
    const { name, contact_number, contact_person, email, accept_cash_payments, accept_card_payments, address, city, suburb, province_id } = siteDetails;

    const [nameError, setNameError] = useState("")
    const [provinceError, setProvinceError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [contactPersonError, setContactPersonError] = useState("")
    const [contactNumberError, setContactNumberError] = useState("")
    const [paymentError, setPaymentError] = useState("")
    const [addressError, setAddressError] = useState("")
    const [cityError, setCityError] = useState("")
    const [suburbError, setSuburbError] = useState("")
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        var value = e.target.value
        if (e.target.name === "accept_card_payments" || e.target.name === "accept_cash_payments") {
            value = (e.target.checked === true) ? 1 : 0
        }

        setSiteDetails({ ...siteDetails, [e.target.name]: value });
    }

    useEffect(() => {
        if (siteId) {
            setLoader(true)
            axios.get("partner_site/" + siteId)
                .then((response) => {
                    setSiteDetails({
                        id: response.data.data.id,
                        partner_id: response.data.data.partner_id,
                        name: response.data.data.name,
                        contact_number: response.data.data.contact_number,
                        contact_person: response.data.data.contact_person,
                        email: response.data.data.email,
                        accept_cash_payments: response.data.data.accept_cash_payments,
                        accept_card_payments: response.data.data.accept_card_payments,
                        address: response.data.data.address,
                        city: response.data.data.city,
                        suburb: response.data.data.suburb,
                        province_id: response.data.data.province_id
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [siteId, navigate])

    useEffect(() => {
        setLoader(true)
        axios.get("/masters")
            .then((response) => {
                setProvinceList(response.data.data.provinces)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false)
                    }
                }
            })
    }, [navigate])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const validate = () => {
        var flag = true
        setNameError("")
        setEmailError("")
        setContactPersonError("")
        setContactNumberError("")
        setPaymentError("")
        setAddressError("")
        setCityError("")
        setSuburbError("")
        setProvinceError("")

        if (name === '') {
            flag = false;
            setNameError("Name field is required")
        }

        var Emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '') {
            flag = false;
            setEmailError("Email field is required")
        } else if (!Emailpattern.test(email)) {
            flag = false
            setEmailError("Please enter valid email address");
        }

        if (contact_person === '') {
            flag = false;
            setContactPersonError("Contact person field is required")
        }

        var pattern = new RegExp(/^[0-9\b]+$/);
        if (contact_number === '') {
            flag = false;
            setContactNumberError("Contact number field is required")
        } else if (contact_number.length < 9 || contact_number.length > 13) {
            flag = false
            setContactNumberError("Please enter valid contact number");

        } else if (!pattern.test(contact_number)) {
            flag = false
            setContactNumberError("Please enter only number");
        }


        if (accept_cash_payments === 0 && accept_card_payments === 0) {
            flag = false;
            setPaymentError("Please select payment method")
        }

        if (address === '') {
            flag = false;
            setAddressError("Address field is required")
        }

        if (city === '') {
            flag = false;
            setCityError("City field is required")
        }

        if (suburb === '') {
            flag = false;
            setSuburbError("Suburb field is required")
        }

        if (province_id === '') {
            flag = false;
            setProvinceError("Province field is required")
        }

        return flag;
    }

    const submitPartnersHandler = (e) => {
        e.preventDefault();
        const flag = validate()
        if (flag) {
            if (siteId > 0) {
                setLoader(true)
                axios.put("/partner_site/" + siteId, siteDetails)
                    .then((response) => {
                        navigate('/partners-sites-details/' + partnerId + "/" + siteId, { state: response.data.status.message })
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            } else {
                setLoader(true)
                axios.post("/partner_site", siteDetails)
                    .then((response) => {
                        navigate("/partners-site/" + partnerId, { state: response.data.status.message })
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    })
            }
        }
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form" onSubmit={submitPartnersHandler}>
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i> {(siteId > 0) ? "EDIT" : "ADD NEW"} SITE</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            <button
                                                type="button"
                                                onClick={cancelHandler}
                                                className="btn btn-emoby-secondary"
                                                style={{ marginRight: "10px" }}
                                            >Cancel
                                            </button>

                                            <button className="btn btn-emoby">Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                NAME
                                            </label>

                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter full name"
                                                value={name}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {nameError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                EMAIL
                                            </label>

                                            <input
                                                className="form-control" id="email"
                                                name="email"
                                                type="text"
                                                placeholder="Enter full email"
                                                value={email}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {emailError}
                                            </small>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Partner</label>
                                            <select
                                                className="form-select digits" id="partner_id"
                                                name='partner_id'
                                            value={partner_id}
                                            onChange={handleChange}
                                            >
                                                <option value="1">option-1</option>
                                                <option value="2">option-2</option>
                                                <option value="3">option-3</option>
                                                <option value="4">option-4</option>
                                                <option value="5">option-5</option>
                                            </select>
                                            <small className="form-text text-danger">
                                                {partnerError}
                                            </small>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Contact Person
                                            </label>
                                            <input
                                                className="form-control" id="contact_person"
                                                name="contact_person"
                                                type="text"
                                                placeholder="Enter contact person name"
                                                value={contact_person}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {contactPersonError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Contact Person Number
                                            </label>
                                            <input
                                                className="form-control" id="contact_number"
                                                name="contact_number" type="text"
                                                placeholder="Enter contact person number"
                                                value={contact_number}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {contactNumberError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="col-form-label pt-0">Accept Cash Payments</label>
                                                    <input className="form-control form-check-input m-0" id="accept_cash_payments" name="accept_cash_payments" type="checkbox" style={{ minWidth: "45px", minHeight: "45px" }} value="1" checked={(accept_cash_payments === 1) ? true : false} onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="col-form-label pt-0">Accept Card Payments</label>
                                                    <input className="form-control form-check-input m-0" id="accept_card_payments" name="accept_card_payments" type="checkbox" style={{ minWidth: "45px", minHeight: "45px" }} value="1" checked={(accept_card_payments === 1) ? true : false} onChange={handleChange} />
                                                </div>
                                            </div>
                                            <small className="form-text text-muted txt-danger">{paymentError}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Address
                                            </label>
                                            <input
                                                className="form-control" id="address"
                                                name="address"
                                                type="text"
                                                placeholder="Enter Address"
                                                value={address}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {addressError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                City
                                            </label>
                                            <input
                                                className="form-control" id="city"
                                                name="city"
                                                type="text"
                                                placeholder="Enter City"
                                                value={city}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {cityError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Suburb
                                            </label>
                                            <input
                                                className="form-control" id="suburb" type="text"
                                                name="suburb"
                                                placeholder="Enter suburb"
                                                value={suburb}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {suburbError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Province
                                            </label>
                                            <select
                                                className="form-select digits" id="province_id"
                                                name="province_id"
                                                value={province_id}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select province</option>
                                                {provinceList.map((province) => (
                                                    <option value={province.id} key={province.id}>{province.name}</option>
                                                ))}
                                            </select>
                                            <small className="form-text text-danger">
                                                {provinceError}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default AddSites