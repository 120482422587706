import React from 'react'

const Loader = () => {
    return (
        <div>
            <div className="loader">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    )
}
export default Loader;