import React, { useState, useEffect } from 'react';
import PartnerTabsHeader from '../PartnerTabsHeader';
import { useParams, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import axios from "../../../axios";
import { ToastContainer, toast } from 'react-toastify';
import Alertbox from '../../../components/Alertbox';
import Loader from '../../../components/Loader';

const ViewSites = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { partnerId } = useParams();
    const [SiteList, setSiteList] = useState([])
    const [SiteListOriginal, setSiteListOriginal] = useState([])
    const [serviceCall, setServiceCall] = useState(true)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("")
    const [alertDelete, setAlertDelete] = useState("")
    const [alertIdDelete, setAlertIdDelete] = useState("")
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (serviceCall) {
            setLoader(true)
            axios.get('partner_sites?partner_id=' + partnerId)
                .then((response) => {
                    setSiteList(response.data.data)
                    setSiteListOriginal(response.data.data)
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [serviceCall, partnerId, location, navigate])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const deleteHandler = (id) => {
        setAlertDelete("Are you sure you want to delete site?")
        setAlertIdDelete(id);
    }

    // const statusHandler = (id, active) => {
    //     setAlert("Are you sure you want to change the status?")
    //     setAlertId(active);
    //     setid(id);
    // }

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempPartner = [...SiteListOriginal].filter(item => (item.name.toLowerCase().includes(searchValue.toLowerCase())));
        setSiteList(tempPartner)
    }

    // const alertResponse = (response, type, alertId) => {
    //     if (response) {
    //         axios
    //             .patch("partner/status/" + id, { active: alertId })
    //             .then((response) => {
    //                 setSuccess(response.data.status.message);
    //                 setServiceCall(true);
    //             })
    //             .catch((error) => {
    //                 if (error.response || error.response.status !== 200) {
    //                     setError(error.response.data.status.message);
    //                 }
    //             });
    //     }
    //     setAlert("")
    // }

    const alertResponseDelete = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .delete("partner_site/" + alertId)
                .then((response) => {
                    setSuccess(response.data.status.message);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlertDelete("")
    }

    return (
        <div className="card">
            {loader ? <Loader /> : null}
            <div className="card-body custom-tab">
                <PartnerTabsHeader active="partners-site" parameters={"/" + partnerId} />
                <div>
                    <div>
                        <ToastContainer theme="colored" />
                        {/* {alert ?
                            <Alertbox
                                message={alert}
                                alertId={alertId}
                                alertResponse={alertResponse} />
                            : null} */}

                        {alertDelete ?
                            <Alertbox
                                message={alertDelete}
                                alertId={alertIdDelete}
                                alertResponse={alertResponseDelete} />
                            : null}
                        <div className="card">
                            <div className="card-header p-3">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <h5>
                                            <div className="mb-3 m-form__group">
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="fa fa-search"></i></span>
                                                    <input
                                                        className="form-control" type="text"
                                                        placeholder="Search"
                                                        onChange={(e) => searchHandler(e.target.value)}
                                                        value={search}
                                                    />
                                                </div>
                                            </div>
                                        </h5>
                                    </div>
                                    <div className="col-sm-8">
                                        <h5 className="add-partners-btn">
                                            <Link to={"/partners-add-sites/" + partnerId}>
                                                <button className="btn btn-emoby float-right" data-bs-original-title="" title=""><i className="fa fa-plus"></i> ADD SITE
                                                </button>
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive" id="customers">
                                <table className="table table-hover">
                                    <thead className="bg-dark">
                                        <tr>
                                            <th scope="col" className="text-light-custom">NAME</th>
                                            {/* <th scope="col" className="text-light-custom">PARTNER</th> */}
                                            <th scope="col" className="text-light-custom">CONTACT PERSON</th>
                                            <th scope="col" className="text-light-custom">CONTACT NUMBER</th>
                                            <th scope="col" className="text-light-custom">EMAIL</th>
                                            <th scope="col" className="text-light-custom">ADDRESS</th>
                                            <th scope="col" className="text-light-custom">CITY</th>
                                            <th scope="col" className="text-light-custom">SUBURB</th>
                                            <th scope="col" className="text-light-custom">PROVINCE</th>
                                            <th scope="col" className="text-light-custom">ACCEPT CASH PAYMENTS</th>
                                            <th scope="col" className="text-light-custom">ACCEPT CARDS PAYMENTS</th>
                                            <th scope="col" className="text-light-custom">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {SiteList.map((data, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Link to={"/partners-sites-details/" + data.partner_id + "/" + data.id} style={{ color: "black", textDecoration: "underline" }}>
                                                        {data.name}
                                                    </Link>
                                                </td>
                                                {/* <td>{data.partner_id}</td> */}
                                                <td>{data.contact_person}</td>
                                                <td>{data.contact_number}</td>
                                                <td>{data.email}</td>
                                                <td>{data.address}</td>
                                                <td>{data.city}</td>
                                                <td>{data.suburb}</td>
                                                <td>{data.province !== null ? data.province.name : ''}</td>
                                                <td>{(data.accept_cash_payments) ? 'Yes' : 'No'}</td>
                                                <td>{(data.accept_card_payments) ? 'Yes' : 'No'}</td>
                                                <td>

                                                    <span
                                                        style={{ cursor: "pointer", fontSize: "18px" }}
                                                        onClick={() => deleteHandler(data.id, data.active)}>
                                                        <i className="fa fa-trash"></i>
                                                    </span> &nbsp;

                                                    {/* <span
                                                        style={{ cursor: "pointer", fontSize: "18px" }}
                                                        onClick={() => statusHandler(data.id, data.active)}>
                                                        <i className="fa fa-refresh"></i>
                                                    </span> &nbsp; */}

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-sm-6 d-flex align-items-center p-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewSites;