import React from 'react';
import { Link } from 'react-router-dom';

const ProductTabsHeader = (props) => {
    return (
        <div>
            <ul className="nav nav-tabs border-tab nav-primary">
                <li className="nav-item">
                    <Link to={"/partners-product-details" + props.parameters} className={"nav-link " + (props.active === 'partners-product-details' ? 'active' : '')}>
                        Details
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default ProductTabsHeader