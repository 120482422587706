import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from "../../../axios";
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router';

const AdminDetails = () => {
    const navigate = useNavigate();
    const { adminId } = useParams();

    const [AdminUserDetails, setAdminUserDetails] = useState({
        name: "",
        partner_id: "",
        site_id: "",
        email: ""
    })
    const { name, email, partner_id, site_id } = AdminUserDetails
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
        axios.get("admin_user/" + adminId)
            .then((response) => {
                setAdminUserDetails({
                    name: response.data.data.name || '',
                    partner_id: response.data.data.partner.name || '',
                    site_id: response.data.data.site.name || '',
                    email: response.data.data.email || '',
                })
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false)
                    }
                }
            })
    }, [adminId, navigate])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6 mt-3">
                                    <div className="card-header pt-1">
                                        <h5><i className="fa fa-user"></i> {name}</h5>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <div className="card-header pt-1 text-end">
                                        <Link to={"/add-admin-users/" + adminId}>
                                            <button className="btn btn-emoby"><i className="fa fa-edit"></i> EDIT ADMIN USER</button>
                                        </Link> &nbsp;
                                        <Link to="/admin-users">
                                            <button className="btn btn-emoby">BACK</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <div className="mb-3">
                                        <label className="col-form-label pt-0" >NAME</label>
                                        <h5>{name}</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <div className="mb-3">
                                        <label className="col-form-label pt-0" >Partner</label>
                                        <h5>{partner_id}</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <div className="mb-3">
                                        <label className="col-form-label pt-0" >Site</label>
                                        <h5>{site_id}</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <div className="mb-3">
                                        <label className="col-form-label pt-0" >Email</label>
                                        <h5>{email}</h5>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDetails