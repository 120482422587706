import React, { useState, useEffect } from 'react';
import SiteTabsHeader from './SiteTabsHeader';
import { Link } from 'react-router-dom';
import { useParams, useLocation, useNavigate } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useSelector } from "react-redux";

const SiteDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { partnerId } = useParams();
    const { siteId } = useParams();
    const userDetails = useSelector((state) => state.getUserDetails.user_details);

    const [siteDetails, setSiteDetails] = useState({
        id: siteId || '',
        partner_id: partnerId,
        name: "",
        contact_number: "",
        contact_person: "",
        email: "",
        accept_cash_payments: 0,
        accept_card_payments: 0,
        address: "",
        city: "",
        suburb: "",
        province_id: "",
    });
    const { name, contact_number, contact_person, email, accept_cash_payments, accept_card_payments, address, city, suburb, province_id } = siteDetails;
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            location.state = null
        }
    }, [success, location])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    useEffect(() => {
        if (location.state !== null) {
            setSuccess(location.state)
        }
        if (siteId) {
            setLoader(true)
            axios.get("partner_site/" + siteId)
                .then((response) => {
                    setSiteDetails({
                        id: response.data.data.id,
                        partner_id: response.data.data.partner_id,
                        name: response.data.data.name,
                        contact_number: response.data.data.contact_number,
                        contact_person: response.data.data.contact_person,
                        email: response.data.data.email,
                        accept_cash_payments: response.data.data.accept_cash_payments,
                        accept_card_payments: response.data.data.accept_card_payments,
                        address: response.data.data.address,
                        city: response.data.data.city,
                        suburb: response.data.data.suburb,
                        province_id: response.data.data.province.name
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [siteId, location, navigate])

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="card">
                <div className="card-body custom-tab">
                    <SiteTabsHeader active="partners-sites-details" parameters={"/" + partnerId + "/" + siteId} />
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 mt-3">
                                            <div className="card-header pt-1">
                                                <h5><i className="fa fa-user"></i> {name}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="card-header pt-1 text-end">
                                                <Link to={"/partners-add-sites/" + partnerId + "/" + siteId}>
                                                    <button className="btn btn-emoby"><i className="fa fa-edit"></i> EDIT SITE</button>
                                                </Link>&nbsp;
                                                {(userDetails.user_type !== 3) ?
                                                    <Link to={"/partners-site/" + partnerId}>
                                                        <button className="btn btn-emoby"> BACK</button>
                                                    </Link>
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >NAME</label>
                                                <h5>{name}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Partner</label>
                                                <h5>{partner_id}</h5>
                                                <hr />
                                            </div>
                                        </div> */}
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Contact Person</label>
                                                <h5>{contact_person}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Contact Person Number</label>
                                                <h5> {contact_number} </h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Email ID</label>
                                                <h5>{email}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Address</label>
                                                <h5>{address}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >City</label>
                                                <h5>{city}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Suburb</label>
                                                <h5>{suburb}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Province</label>
                                                <h5>{province_id}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Cash payments</label>
                                                <h5>{accept_cash_payments ? 'Yes' : 'No'}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Card payments</label>
                                                <h5>{accept_card_payments ? 'Yes' : 'No'}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteDetails