import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const AddAdminUsers = () => {
    const { adminId } = useParams();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.getUserDetails.user_details);

    const [adminUserDetails, setAdminUsersDetails] = useState({
        name: "",
        surname: "",
        email: "",
        password: "",
        mobile_number: "",
        user_type: 2,
        partner_id: "",
        site_id: ""
    })
    const { name, surname, email, password, mobile_number, partner_id, site_id, user_type } = adminUserDetails;

    const [cpassword, setCPassword] = useState("")
    const [partnerList, setPartnerList] = useState([])
    const [siteList, setSiteList] = useState([])
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)
    const [nameError, setNameError] = useState("")
    const [surnameError, setSrnameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [mobileNoError, setMobileNoError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [cpasswordError, setCPasswordError] = useState("")
    const [partnerError, setPartnerError] = useState("")
    const [siteError, setSiteError] = useState("")
    const [userTypeError, setUserTypeError] = useState("")

    const handleChange = (e) => {
        setAdminUsersDetails({ ...adminUserDetails, [e.target.name]: e.target.value });
    }


    useEffect(() => {
        if (adminId) {
            setLoader(true)
            axios.get("admin_user/" + adminId)
                .then((response) => {
                    setAdminUsersDetails({
                        name: response.data.data.name || '',
                        partner_id: response.data.data.partner_id || '',
                        site_id: response.data.data.site_id || '',
                        email: response.data.data.email || '',
                        surname: response.data.data.surname || '',
                        mobile_number: response.data.data.mobile_number || '',
                        password: response.data.data.password || ''
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [adminId, navigate])

    useEffect(() => {
        if (userDetails.user_type === 1) {
            setLoader(true)
            axios.get("partners")
                .then((response) => {
                    setPartnerList(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, userDetails])


    // const handleSite = () => {

    // }

    useEffect(() => {
        var temp_partner_id = (userDetails.user_type === 2) ? userDetails.partner_id : partner_id;
        if (temp_partner_id) {
            setLoader(true)
            axios.get("partner_sites?partner_id=" + temp_partner_id)
                .then((response) => {
                    setSiteList(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, partner_id, userDetails])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const validate = () => {
        var flag = true;
        setNameError("")
        setSrnameError("")
        setEmailError("")
        setMobileNoError("")
        setPasswordError("")
        setCPasswordError("")
        setPartnerError("")
        setSiteError("")
        setUserTypeError("")

        if (name === "") {
            flag = false;
            setNameError("Name field is required")
        }


        if (surname === "") {
            flag = false;
            setSrnameError("Surname field is required")
        }

        if (!adminId) {
            var Emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (email === "") {
                flag = false;
                setEmailError("Email field is required")
            } else if (!Emailpattern.test(email)) {
                flag = false
                setEmailError("Please enter valid email address");
            }
        }


        if (!adminId) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (mobile_number === "") {
                flag = false;
                setMobileNoError("Mobile Number field is required")
            } else if (mobile_number.length < 9 || mobile_number.length > 13) {
                flag = false
                setMobileNoError("Please enter valid contact number");

            } else if (!pattern.test(mobile_number)) {
                flag = false
                setMobileNoError("Please enter only number");
            }
        }



        if (!adminId) {
            if (password === "") {
                flag = false;
                setPasswordError("Password field is required")
            }


            if (cpassword === "") {
                flag = false;
                setCPasswordError("Confirm Password field is required")
            }

            if (password !== cpassword) {
                flag = false;
                setCPasswordError("Password & confirm password field doesn't match!")
            }
        }


        if (userDetails.user_type === 1) {
            if (partner_id === "") {
                flag = false;
                setPartnerError("Partner field is required")
            }
        }

        if (userDetails.user_type === 3) {
            if (site_id === "") {
                flag = false;
                setSiteError("Site field is required")
            }
        }

        if (!adminId) {
            if (user_type === "") {
                flag = false;
                setUserTypeError("User Type field is required")
            }
        }

        return flag

    }


    const submitAdminUser = (e) => {
        e.preventDefault();
        const flag = validate();
        if (flag) {
            if (adminId > 0) {
                setLoader(true)
                axios.put("admin_user/" + adminId, adminUserDetails)
                    .then((response) => {
                        navigate('/admin-users', { state: response.data.status.message })
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    })
            } else {
                setLoader(true)
                axios.post("admin_user", adminUserDetails)
                    .then((response) => {
                        navigate('/admin-users', { state: response.data.status.message })
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    })
            }
        }
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form"
                                onSubmit={submitAdminUser}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i>  {(adminId > 0) ? "EDIT " : "ADD"} ADMIN USER</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            <button type="submit"
                                                className="btn btn-emoby">Submit</button>
                                            &nbsp;
                                            <Link to="/admin-users">
                                                <button
                                                    type="button"
                                                    className="btn btn-emoby"
                                                >Back
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    {(adminId > 0) ? null :
                                        <div className="col-sm-12 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >User Type</label>
                                                <select
                                                    className="form-select digits" id="user_type"
                                                    name='user_type'
                                                    value={user_type}
                                                    onChange={handleChange}
                                                >
                                                    {userDetails.user_type === 2 ? "" :
                                                        <option value="2">Partner User</option>
                                                    }
                                                    <option value="3">Site User</option>
                                                </select>
                                                <small className="form-text text-danger">
                                                    {userTypeError}
                                                </small>
                                            </div>
                                        </div>
                                    }


                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                NAME
                                            </label>
                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter name"
                                                value={name}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {nameError}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                SURNAME
                                            </label>
                                            <input
                                                className="form-control" id="surname"
                                                name="surname"
                                                type="text"
                                                placeholder="Enter surname"
                                                value={surname}
                                                onChange={handleChange}
                                            />
                                            <small className="form-text text-danger">
                                                {surnameError}
                                            </small>
                                        </div>
                                    </div>

                                    {(adminId > 0) ? null :
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >
                                                    EMAIL
                                                </label>
                                                <input
                                                    className="form-control" id="email"
                                                    name="email"
                                                    type="text"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={handleChange}
                                                />
                                                <small className="form-text text-danger">
                                                    {emailError}
                                                </small>
                                            </div>
                                        </div>
                                    }

                                    {(adminId > 0) ? null :
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >
                                                    MOBILE NUMBER
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="mobile_number"
                                                    name="mobile_number"
                                                    type="text"
                                                    placeholder="Enter mobile number"
                                                    value={mobile_number}
                                                    onChange={handleChange}
                                                />
                                                <small className="form-text text-danger">
                                                    {mobileNoError}
                                                </small>
                                            </div>
                                        </div>
                                    }
                                    {(adminId > 0) ? null :

                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >
                                                    PASSWORD
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    placeholder="Enter password"
                                                    value={password}
                                                    onChange={handleChange}
                                                />
                                                <small className="form-text text-danger">
                                                    {passwordError}
                                                </small>
                                            </div>
                                        </div>
                                    }
                                    {(adminId > 0) ? null :

                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >
                                                    CONFIRM PASSWORD
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="cpassword"
                                                    name="cpassword"
                                                    type="password"
                                                    placeholder="Enter confirm password"
                                                    value={cpassword}
                                                    onChange={(e) => setCPassword(e.target.value)}
                                                />
                                                <small className="form-text text-danger">
                                                    {cpasswordError}
                                                </small>
                                            </div>
                                        </div>
                                    }

                                    {(userDetails.user_type === 1) ?
                                        <div className="col-sm-6 mt-3">
                                            <div className="mb-3">
                                                <label className="col-form-label pt-0" >Partner</label>
                                                <select
                                                    className="form-select digits" id="partner_id"
                                                    name='partner_id'
                                                    value={partner_id}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Choose Partner</option>
                                                    {partnerList.map((item) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <small className="form-text text-danger">
                                                    {partnerError}
                                                </small>
                                            </div>
                                        </div> : null
                                    }
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Site</label>
                                            <select
                                                className="form-select digits" id="site_id"
                                                name='site_id'
                                                value={site_id}
                                                onChange={handleChange}
                                            >
                                                <option value="">Choose Site</option>
                                                {
                                                    siteList.map((item) => (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            <small className="form-text text-danger">
                                                {siteError}
                                            </small>
                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAdminUsers