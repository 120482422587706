import React from 'react';
import { Link } from 'react-router-dom';

const SiteTabsHeader = (props) => {
    return (
        <div>
            <ul className="nav nav-tabs border-tab nav-primary">
                <li className="nav-item">
                    <Link to={"/partners-sites-details" + props.parameters} className={"nav-link " + (props.active === 'partners-sites-details' ? 'active' : '')}>
                        Details
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/partners-sites-wifi" + props.parameters}
                        className={"nav-link " + (props.active === 'partners-sites-wifi' ? 'active' : '')}>
                        Wifis
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/partners-sites-zone" + props.parameters} className={"nav-link " + (props.active === 'partners-sites-zone' ? 'active' : '')}>
                    {/* <Link to={"#"} className={"nav-link " + (props.active === 'partners-sites-zone' ? 'active' : '')}> */}
                        Zones
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={"/partners-sites-map" + props.parameters} className={"nav-link " + (props.active === 'partners-sites-map' ? 'active' : '')}>
                    {/* <Link to={"#"} className={"nav-link " + (props.active === 'partners-sites-map' ? 'active' : '')}> */}
                        Map
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default SiteTabsHeader