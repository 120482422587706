import React, { useState, useEffect } from 'react'
import SiteTabsHeader from '../SiteTabsHeader';
// import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import axios from "../../../../axios";
import Alertbox from '../../../../components/Alertbox';
import Loader from '../../../../components/Loader';
// import filemp3 from './demo.mp3'

const ViewZone = () => {
    const navigate = useNavigate();
    const { partnerId } = useParams();
    const { siteId } = useParams();

    const [zoneList, setZoneList] = useState([])
    const [zoneListOriginal, setZoneListOriginal] = useState([])
    const [serviceCall, setServiceCall] = useState(true)
    const [error, setError] = useState("");
    const [search, setSearch] = useState("")
    const [alertDelete, setAlertDelete] = useState("")
    const [alertIdDelete, setAlertIdDelete] = useState("")
    const [alertType, setAlertType] = useState("")
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(false)

    const [UploadFile, setUploadFile] = useState('')
    const [FileData, setFileData] = useState('')
    const [PlayingUrl, setPlayingUrl] = useState(false);
    const [audio, setAudio] = useState(new Audio());

    useEffect(() => {
        if (serviceCall) {
            setLoader(true)
            axios.get("partner_site_zones?partner_site_id=" + siteId)
                .then((response) => {
                    setZoneList(response.data.data)
                    setZoneListOriginal(response.data.data)
                    setServiceCall(false)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [navigate, siteId, serviceCall])

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const tempZone = [...zoneListOriginal].filter(item => (item.name.toLowerCase().includes(searchValue.toLowerCase())));
        setZoneList(tempZone)
    }

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])


    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const deleteHandler = (id) => {
        setAlertType("partner_site_zone")
        setAlertDelete("Are you sure you want to delete zone?")
        setAlertIdDelete(id);
    }

    const alertResponseDelete = (response, type, alertId) => {
        if (response) {
            if (type === 'partner_site_zone') {
                setLoader(true)
                axios
                    .delete(type + "/" + alertId)
                    .then((response) => {
                        setSuccess(response.data.status.message);
                        setServiceCall(true);
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    });
            }

            if (type === 'partner_site_zone_audio_remove') {
                setPlayingUrl('')
                setLoader(true)
                axios
                    .put(type + "/" + alertId)
                    .then((response) => {
                        setSuccess(response.data.status.message);
                        setServiceCall(true);
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false)
                            }
                        }
                    });
            }
        }
        setAlertDelete("")
        setAlertType("")
        setAlertIdDelete('');
    }

    const fileHandler = (e) => {
        setFileData(e.target.files[0])
    }

    const UploadFileHandler = () => {
        if (!FileData) {
            setError('Please select file')
            return false
        }

        const formData = new FormData();
        formData.append('audio', FileData)
        formData.append('partner_site_zone_id', UploadFile)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        setLoader(true)
        axios.post('partner_site_zone_audio', formData, config)
            .then((response) => {
                // console.log(response)
                setSuccess(response.data.status.message);
                setUploadFile('')
                setFileData('')
                setLoader(false)
                setServiceCall(true)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setLoader(false)
                        setError(error.response.data.status.message);
                    }
                }
            })
    }

    useEffect(() => {
        PlayingUrl ? audio.play() : audio.pause();
    }, [PlayingUrl, audio]);


    const removeFile = (zone_id) => {
        setAlertType("partner_site_zone_audio_remove")
        setAlertDelete("Are you sure you want to delete this audio file?")
        setAlertIdDelete(zone_id);
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            {alertDelete ?
                <Alertbox
                    message={alertDelete}
                    alertId={alertIdDelete}
                    type={alertType}
                    alertResponse={alertResponseDelete} />
                : null}
            <div className="card">
                <div className="card-body custom-tab">
                    <SiteTabsHeader active="partners-sites-zone" parameters={"/" + partnerId + "/" + siteId} />
                    <div className="card">
                        <div className="card-header p-3">
                            <div className="row">
                                <div className="col-sm-4">
                                    <h5>
                                        <div className="mb-3 m-form__group">
                                            <div className="input-group">
                                                <span className="input-group-text"><i className="fa fa-search"></i></span>
                                                <input
                                                    className="form-control" type="text"
                                                    placeholder="Search"
                                                    onChange={(e) => searchHandler(e.target.value)}
                                                    value={search}
                                                />
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                                <div className="col-sm-8">
                                    <h5 className="add-partners-btn">
                                        {/* <Link to={"/partners-add-zone/" + partnerId + "/" + siteId}>
                      <button className="btn btn-emoby float-right" data-bs-original-title="" title=""><i className="fa fa-plus"></i> ADD ZONE
                      </button>
                    </Link> */}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="customers">
                            <table className="table table-hover">
                                <thead className="bg-dark">
                                    <tr>
                                        <th scope="col" className="text-light-custom">NAME</th>
                                        <th scope="col" className="text-light-custom">TYPE</th>
                                        <th scope="col" className="text-light-custom" style={{ width: '500px' }}>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {zoneList.map((zone, index) => (
                                        <tr key={index}>
                                            <td>{zone.name}</td>
                                            <td>{zone.zone_type.name}</td>
                                            <td style={{ display: 'flex' }}>

                                                {
                                                    (UploadFile === zone.id) ?
                                                        <>
                                                            <span style={{ marginLeft: '10px' }}>
                                                                <input onChange={fileHandler} className="form-control" type="file" accept=".mp3,audio/*" />
                                                            </span>
                                                            <span
                                                                style={{ cursor: "pointer", fontSize: "18px", marginLeft: '10px', padding: '0.375rem 0.75rem' }}
                                                                onClick={UploadFileHandler}
                                                                className="btn btn-success"
                                                                title="Cancel"
                                                            >
                                                                <i className="fa fa-upload"></i>
                                                            </span>
                                                            <span
                                                                style={{ cursor: "pointer", fontSize: "18px", marginLeft: '10px', padding: '0.375rem 0.75rem' }}
                                                                onClick={() => {
                                                                    setUploadFile('')
                                                                    setFileData('')
                                                                }}
                                                                className="btn btn-danger"
                                                                title="Cancel"
                                                            >
                                                                <i className="fa fa-times"></i>
                                                            </span>
                                                        </>
                                                        :
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", fontSize: "18px" }}
                                                                onClick={() => deleteHandler(zone.id)}>
                                                                <i title="Delete zone" className="fa fa-trash"></i>
                                                            </span>

                                                            {
                                                                (zone.zone_type.allow_audio_files === 1) ? <>

                                                                    {
                                                                        zone.audio_file ?
                                                                            <>
                                                                                <span
                                                                                    style={{ cursor: 'pointer', fontSize: "18px", marginLeft: '10px' }}
                                                                                >
                                                                                    {PlayingUrl === zone.audio_file ? <i title="Stop" className="fa fa-stop" onClick={() => setPlayingUrl('')}></i> : <i title="Play" className="fa fa-play" onClick={() => {
                                                                                        setPlayingUrl(zone.audio_file)
                                                                                        setAudio(new Audio(zone.audio_file))
                                                                                    }
                                                                                    }></i>}
                                                                                </span>
                                                                                <span
                                                                                    style={{ cursor: 'pointer', fontSize: "18px", marginLeft: '10px' }}
                                                                                >
                                                                                    <i title="Remove file" className="fa fa-file-audio on fa-ban" onClick={() => removeFile(zone.id)}></i>
                                                                                </span>
                                                                            </> :
                                                                            <span
                                                                                onClick={() => {
                                                                                    setUploadFile(zone.id)
                                                                                    setFileData('')
                                                                                    setPlayingUrl('')
                                                                                }}
                                                                                style={{ cursor: 'pointer', fontSize: "18px", marginLeft: '10px' }}
                                                                            >
                                                                                <i title="Upload MP3" className="fa fa-upload"></i>
                                                                            </span>
                                                                    }
                                                                </> : null
                                                            }

                                                        </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="p-3">
                                <div className="row">
                                    <div className="col-sm-6 d-flex align-items-center p-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ViewZone