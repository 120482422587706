import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";

if (window.location.hostname !== "localhost") {
  if (window.location.protocol === 'http:') {
    console.log("you are accessing us via an insecure protocol (HTTP). Redirecting you to HTTPS.");
    window.location.href = window.location.href.replace('http:', 'https:');
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <BrowserRouter basename="/emoby-admin/"> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

