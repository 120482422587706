import React, { useState, useEffect } from 'react';
import axios from '../../../axios';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/Loader';

const AddPartners = () => {
    const { partnerId } = useParams();
    const navigate = useNavigate();
    const [partnerDetails, setPartnerDetails] = useState({
        id: partnerId || '',
        name: "",
        // partner_id: "",
        contact_person: "",
        contact_number: "",
        email: "",
        address: "",
        city: "",
        suburb: "",
        province_id: "",
        active: 0
    });
    const { name, contact_person, contact_number, email, address, city, suburb, province_id } = partnerDetails;

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [nameError, setNameError] = useState("")
    // const [partnerError, setPartnerError] = useState("")
    const [contactPersonError, setContactPersonError] = useState("")
    const [contactNumberError, setContactNumberError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [addressError, setAddressError] = useState("")
    const [cityError, setCityError] = useState("")
    const [suburbError, setSuburbError] = useState("")
    const [provinceError, setProvinceError] = useState("")
    const [provinceList, setProvinceList] = useState([])
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        setPartnerDetails({ ...partnerDetails, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (partnerId) {
            setLoader(true);
            axios.get("partner/" + partnerId)
                .then((response) => {
                    setPartnerDetails({
                        id: response.data.data.id,
                        name: response.data.data.name,
                        // partner_id: response.data.data.partner_id,
                        contact_person: response.data.data.contact_person,
                        contact_number: response.data.data.contact_number,
                        email: response.data.data.email,
                        address: response.data.data.address,
                        city: response.data.data.city,
                        suburb: response.data.data.suburb,
                        province_id: response.data.data.province_id,
                        active: response.data.data.active,
                    })
                    setLoader(false);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false);
                        }
                    }
                })
        }
    }, [partnerId, navigate])


    const validate = () => {
        var flag = true
        setNameError("")
        // setPartnerError("")
        setContactNumberError("")
        setContactPersonError("")
        setEmailError("")
        setAddressError("")
        setCityError("")
        setSuburbError("")
        setProvinceError("")

        if (name === '') {
            flag = false;
            setNameError("Name field is required")
        }

        // if (partner_id === '') {
        //     flag = false;
        //     setPartnerError("Partner field is required")
        // }
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (contact_number === '') {
            flag = false;
            setContactNumberError("Contact Number field is required")
        } else if (contact_number.length < 9 || contact_number.length > 13) {
            flag = false
            setContactNumberError("Please enter valid contact number");

        } else if (!pattern.test(contact_number)) {
            flag = false
            setContactNumberError("Please enter only number");
        }

        if (contact_person === '') {
            flag = false;
            setContactPersonError("Contact Person field is required")
        }

        var Emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '') {
            flag = false;
            setEmailError("Email field is required")
        } else if (!Emailpattern.test(email)) {
            flag = false
            setEmailError("Please enter valid email address");
        }

        if (address === '') {
            flag = false;
            setAddressError("Address field is required")
        }

        if (city === '') {
            flag = false;
            setCityError("City field is required")
        }

        if (suburb === '') {
            flag = false;
            setSuburbError("Suburb field is required")
        }

        if (province_id === '') {
            flag = false;
            setProvinceError("Province field is required")
        }

        return flag;
    }

    const submitPartnersHandler = (e) => {
        e.preventDefault();
        const flag = validate()
        if (flag) {
            if (partnerId > 0) {
                setLoader(true)
                axios.put("/partner/" + partnerId, partnerDetails)
                    .then((response) => {
                        navigate("/partners-details/" + partnerId, { state: response.data.status.message })
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false);
                            }
                        }
                    })
            } else {
                setLoader(true)
                axios.post("/partner", partnerDetails)
                    .then((response) => {
                        navigate("/partners", { state: response.data.status.message })
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                navigate('/logout')
                            } else if (error.response.status === 402) {
                                navigate('/unauthorized')
                            } else if (error.response || error.response.status !== 200) {
                                setError(error.response.data.status.message);
                                setLoader(false);
                            }
                        }
                    })
            }
        }

    }

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    useEffect(() => {
        setLoader(true)
        axios.get("/masters")
            .then((response) => {
                setProvinceList(response.data.data.provinces)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false)
                    }
                }
            })
    }, [navigate])

    const cancelHandler = () => {
        navigate(-1)
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form" onSubmit={submitPartnersHandler}>
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i> {(partnerId > 0) ? "EDIT" : "ADD NEW"}  PARTNER</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            <button
                                                type="button"
                                                onClick={cancelHandler}
                                                className="btn btn-emoby-secondary"
                                                style={{ marginRight: "10px" }}
                                            >Cancel
                                            </button>

                                            <button className="btn btn-emoby">Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                NAME
                                            </label>

                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter Full Name"
                                                value={name}
                                                onChange={handleChange} />
                                            <small className="form-text text-danger">{nameError}</small>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 mt-3">
                                    <div className="mb-3">
                                        <label className="col-form-label pt-0" >Partner</label>
                                        <select
                                            className="form-select digits" id="partner_id"
                                            name='partner_id'
                                            value={partner_id}
                                            onChange={handleChange}>
                                            <option value="1">Oprion-1</option>
                                        </select>
                                        <small className="form-text text-danger">{partnerError}</small>
                                    </div>
                                </div> */}
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >Contact Person
                                            </label>
                                            <input
                                                className="form-control" id="contact_person"
                                                name="contact_person"
                                                type="text"
                                                placeholder="Enter Contact Person Name"
                                                value={contact_person}
                                                onChange={handleChange} />
                                            <small className="form-text text-danger">{contactPersonError}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Contact Person Number
                                            </label>
                                            <input
                                                className="form-control" id="contact_number"
                                                name="contact_number" type="text"
                                                placeholder="Enter Contact Person Number"
                                                value={contact_number}
                                                onChange={handleChange} />
                                            <small className="form-text text-danger">{contactNumberError}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Email ID
                                            </label>
                                            <input
                                                className="form-control" id="email"
                                                name="email"
                                                type="text"
                                                placeholder="Enter Email ID"
                                                value={email}
                                                onChange={handleChange} />
                                            <small className="form-text text-danger">{emailError}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Address
                                            </label>
                                            <input
                                                className="form-control" id="address"
                                                name="address"
                                                type="text"
                                                placeholder="Enter Address"
                                                value={address}
                                                onChange={handleChange} />
                                            <small className="form-text text-danger">{addressError}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                City
                                            </label>
                                            <input
                                                className="form-control" id="city"
                                                name="city"
                                                type="text"
                                                placeholder="Enter City"
                                                value={city}
                                                onChange={handleChange} />
                                            <small className="form-text text-danger">{cityError}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Suburb
                                            </label>
                                            <input
                                                className="form-control" id="suburb" type="text"
                                                name="suburb"
                                                placeholder="Enter suburb"
                                                value={suburb}
                                                onChange={handleChange} />
                                            <small className="form-text text-danger">{suburbError}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Province
                                            </label>
                                            <select
                                                className="form-select digits" id="province_id"
                                                name="province_id"
                                                value={province_id}
                                                onChange={handleChange}>
                                                <option>Select Province</option>
                                                {provinceList.map((province) => (
                                                    <option value={province.id}
                                                        key={province.id}>{province.name}</option>
                                                ))}
                                            </select>
                                            <small className="form-text text-danger">{provinceError}</small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default AddPartners;