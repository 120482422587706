import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import VehicleTabsHeader from '../VehicleTabsHeader';
import axios from "../../../../axios"
import Loader from '../../../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const ViewQRCode = () => {
    const navigate = useNavigate();
    const { partnerId } = useParams();
    const { vehicleId } = useParams();

    const [qrCode, setQRCode] = useState('')
    const [qrCodeURL, setQRCodeURL] = useState('')
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
        axios.get("vehicle/" + vehicleId)
            .then((response) => {
                setQRCode(response.data.data.qrcode_url)
                setQRCodeURL(response.data.data.qrcode_frontend_link)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false)
                    }
                }
            })
    }, [navigate, vehicleId])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="card">
                <div className="card-body custom-tab">
                    <VehicleTabsHeader active="partners-vehicle-qrcode" parameters={"/" + partnerId + "/" + vehicleId} />

                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 mt-3 text-center">
                                            <img
                                                src={qrCode}
                                                alt={qrCodeURL}
                                                style={{ width: '50%' }}
                                            />
                                            <p>{qrCodeURL}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewQRCode