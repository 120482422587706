import React from 'react'

const Alertbox = (props) => {
  return (
    <div>
      <div className="custom-modal-overlay">
        <div className="col custom-modal-ui">
          <div className="card mb-5 mb-lg-0">
            <div className="card-body custom-modal-card">
              <div className="custom-modal-header">
                <h2 className="custom-modal-header-title">
                  Please Confirm
                </h2>
              </div>
              <p className="custom-modal-header-title-p">
                {props.message}
                </p>
              <div className="custom-modal-header-title-footer">
                <button
                  onClick={() => props.alertResponse(true, props.type, props.alertId)}
                  className="btn btn-primary my-2"
                >
                  Yes
                </button>
                &nbsp;
                <button
                  onClick={() => props.alertResponse(false, props.type, props.alertId)}
                  className="btn btn-secondary my-2"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Alertbox