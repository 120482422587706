import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import axios from "../../../../axios";
import { toast } from 'react-toastify';

const PanelComponent = (props) => {
    const navigate = useNavigate();
    const [contentValue, setContentValue] = useState(<br />)
    const [zoneTypesDetails, setZoneTypesDetails] = useState([])
    const [error, setError] = useState("");
    
    useEffect(() => {
        axios.get("/masters")
            .then((response) => {
                setZoneTypesDetails(response.data.data.zone_types)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.message);
                    }
                }
            })
    }, [navigate])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    return (
        <div className="sticky">
            <div className="tool-header">
                ZONE TOOLBAR
            </div>
            <div className="tool-body">

                {(zoneTypesDetails.map((zoneType, index) => (
                    <div key={zoneType.id}>
                        <i className="fa fa-arrows-alt"
                            style={{ backgroundColor: zoneType.color_code, border: (props.zoneTypeId === zoneType.id) ? '2px solid #ffffff' : '' }}
                            aria-hidden="true"
                            onClick={(e) => props.handleZone(zoneType.id, zoneType.color_code)}
                            onMouseEnter={() => setContentValue("Create an " + zoneType.name + " Zone")} onMouseLeave={() => setContentValue(<br />)}></i>
                    </div>
                )))}

                {/* <i className="fa fa-arrows-alt tool-green" aria-hidden="true"
                    name="Allowed"
                    onClick={props.handleZone}
                    onMouseEnter={() => setContentValue("Create an Allowed Zone")} onMouseLeave={() => setContentValue(<br />)}></i>

                <i
                    className="fa fa-arrows-alt tool-red"
                    name="Restricted"
                    onClick={props.handleZone}
                    aria-hidden="true"
                    onMouseEnter={() => setContentValue("Create a Restricted Zone")} onMouseLeave={() => setContentValue(<br />)}></i> */}

                {/* <i className="fa fa-arrows-alt tool-blue"
                    name="Home"
                    onClick={props.handleZone}
                    aria-hidden="true"
                    onMouseEnter={() => setContentValue("Create an Return Zone")} onMouseLeave={() => setContentValue(<br />)}></i> */}

                <i className="fa fa-pencil"
                    onClick={props.EditPathDetails}
                    aria-hidden="true"
                    onMouseEnter={() => setContentValue("Edit Existing Zone")} onMouseLeave={() => setContentValue(<br />)}></i>

                <i className="fa fa-floppy-o"
                    onClick={props.savePathDetails}
                    aria-hidden="true"
                    onMouseEnter={() => setContentValue("Save Zone")} onMouseLeave={() => setContentValue(<br />)}></i>

                <i className="fa fa-times"
                    onClick={props.CloseButton}
                    aria-hidden="true"
                    onMouseEnter={() => setContentValue("Stop Editing")} onMouseLeave={() => setContentValue(<br />)}></i>
            </div>
            <div className="tool-footer">
                {contentValue}
            </div>
        </div >

    )
}

export default PanelComponent