import React from 'react';
import { Link } from 'react-router-dom';

const PartnerTabsHeader = (props) => {
   
   return (
      <ul className="nav nav-tabs border-tab nav-primary">
         <li className="nav-item">
            <Link to={"/partners-details" + props.parameters} className={"nav-link " + (props.active === 'partners-details' ? 'active' : '')}>
               Details
            </Link>
         </li>
         <li className="nav-item">
            <Link to={"/partners-site" + props.parameters} className={"nav-link " + (props.active === 'partners-site' ? 'active' : '')}>
               Sites
            </Link>
         </li>
         <li className="nav-item">
            <Link to={"/partners-product" + props.parameters} className={"nav-link " + (props.active === 'partners-product' ? 'active' : '')}>
            {/* <Link to="#" className={"nav-link "}> */}
               Products
            </Link>
         </li>
         <li className="nav-item">
            <Link to={"/partners-vehicle" + props.parameters} className={"nav-link " + (props.active === 'partners-vehicle' ? 'active' : '')}>
            {/* <Link to="#" className={"nav-link "}> */}
               Vehicles
            </Link>
         </li>
      </ul>
   )
}

export default PartnerTabsHeader