import React from 'react'

const AddCsvDownload = () => {
    return (
        <div>
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            <form className="theme-form"
                            //  onSubmit={submitPartnersHandler}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                         <div className="card-header pt-1">
                                             {/* <h5><i className="fa fa-plus"></i> ADD NEW SITE</h5> */}
                                       </div>
                                   </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">                                         

                                            <button className="btn btn-emoby">Download</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                PARTNER
                                            </label>
                                            <select
                                                className="form-select digits" id="province_id"
                                                name="province_id"
                                                // value={province_id}
                                                // onChange={handleChange}
                                                >
                                               <option value="1">Option 1</option>
                                            </select>
                                            {/* <small className="form-text text-danger">{provinceError}
                                            </small> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                FROM DATE
                                            </label>

                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter NAME"
                                            // value={name}
                                            // onChange={handleChange} 
                                            />
                                            <small className="form-text text-danger">
                                                {/* {nameError} */}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                TO DATE
                                            </label>

                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter NAME"
                                            // value={name}
                                            // onChange={handleChange} 
                                            />
                                            <small className="form-text text-danger">
                                                {/* {nameError} */}
                                            </small>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCsvDownload